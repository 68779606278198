import { Box, Container, styled } from "@mui/material";
import React from "react";
import styles from "./index.module.css";


const BannerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export default function CustomBanner(props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  return (
    <Box  className={styles.bannerMain}>
    <Container maxWidth="xl">
    <BannerWrapper className={styles.bannerContainer}
      sx={{ paddingX: { xl: "6rem", md: "1rem", sm: "1rem", xs: "1rem" } }}
    >
      {props.children}
    </BannerWrapper>
    </Container>
    </Box>
  );
}
