import { Box, Container, Typography, styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { useQuery } from "@apollo/client";
import arrowWhycloudUp from "@/assets/images/ARROW.svg";
import arrowWhycloudUpAnimated from "@/assets/images/ARROWAnimated.gif";
import styles from "./service.module.css";
import "swiper/css";
import { useEffect } from "react";
import SectionHeading from "@/components/section-heading";
import noImage from "@/assets/images/noImage.png";
import {
  GetWhyCloudUpQuery,
  GetWhyCloudUpQueryFragmentResult,
  WhyCloudUp,
} from "@/model/GetWhyCloudUpFragment";
import HorizontalRuler from "@/components/horizontal-ruler-heading";

export default function WhyCloudUpSection() {
  const cloudUpFragment = useQuery(GetWhyCloudUpQuery);
  let data: GetWhyCloudUpQueryFragmentResult =
    cloudUpFragment.data;
  const loading: boolean = cloudUpFragment.loading;

  useEffect(() => {
    if (cloudUpFragment) {
      // console.log("serviceFragment:", cloudUpFragment);
    } else {
      // console.log("Banner fragment data is not available");
    }
  });

  return (
    <>
      <Box>
        {!loading &&
          data &&
          data.whyCloudUpCollection &&
          data.whyCloudUpCollection.items.length > 0 && (
            <Box className={styles.serviceSectionMain}>
              <Container maxWidth="xl">
                <Box
                  paddingBottom="100px"
                  sx={{
                    paddingX: {
                      xl: "6rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                  }}
                >
                  <Box>
                    <Box display="flex" justifyContent="center">
                      {" "}
                      <Box sx={{ width: { md: "60%", sm: "80%", xs: "100%" } }}>
                        <SectionHeading
                          color="#3C4858"
                          HeadingId="6dV7XqZDNKdUpCYaqMdBt"
                        ></SectionHeading>
                        <HorizontalRuler />
                      </Box>
                    </Box>
                    <Box className={styles.WhyCloudScale}>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          flexDirection: { md: "row", xs: "row" },
                          justifyContent: {
                            md: "space-between",
                            xs: "space-between",
                          },
                        }}
                      >
                        <Typography
                          paddingY="2rem"
                          color="#3C4858"
                          marginBottom="0.5rem"
                          sx={{
                            fontSize: { md: "30px", xs: "22px" },
                            fontWeight: { md: "bolder", xs: "bold" },
                          }}
                          alignItems="center"
                          textAlign="center"
                        >
                          {data?.whyCloudUpCollection?.items[0]?.title}
                        </Typography>
                        <Box
                          paddingY="1rem"
                          paddingLeft="1rem"
                          sx={{
                            width: { md: "50%", xs: "50%" },
                            textAlign: { md: "left", xs: "left" },
                          }}
                        >
                          {
                            data?.whyCloudUpCollection?.items[0]
                              ?.descriptionwhycloudup
                          }
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        borderRadius="8px"
                        bgcolor="white"
                        boxShadow="0 3px 3px 0 rgb(2 34 131 / 15%)"
                        borderTop="5px solid 
                      #E5448A"
                        padding="1rem"
                        sx={{
                          flexDirection: { md: "row", xs: "row" },
                          justifyContent: {
                            md: "space-evenly",
                            xs: "space-evenly",
                          },
                        }}
                      >
                        {data?.whyCloudUpCollection?.items[0]?.categoryItemsCollection?.items
                          ?.filter((item) => item != null)
                          .map((item, index) => (
                            <Box
                              height="100%"
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              sx={{
                                paddingY: { md: "orem", xs: "orem" },
                                width: {
                                  xs: `${
                                    100 /
                                    data?.whyCloudUpCollection?.items[0]
                                      ?.categoryItemsCollection?.items?.length
                                  }%`,
                                },
                              }}
                            >
                              <Box display="flex" justifyContent="center">
                                {" "}
                                <Box
                                  padding="0.5rem"
                                  component="img"
                                  src={
                                    item?.icon?.url ? item?.icon?.url : noImage
                                  }
                                  alt={item?.title}
                                  width="70px"
                                  height="70px"
                                />
                              </Box>
                              <Box
                                textAlign="center"
                                fontSize="13px"
                                padding="5px"
                                paddingY="0.5rem"
                              >
                                {item?.title}
                              </Box>
                            </Box>
                          ))}
                      </Box>

                      <Box
                        position="relative"
                        display="flex"
                        sx={{ flexDirection: { md: "row", xs: "row" } }}
                        justifyContent="space-between"
                      >
                        <Box
                          marginTop="2.5rem"
                          zIndex="1"
                          sx={{ width: { md: "45%", xs: "45%" } }}
                        >
                          {" "}
                          <Typography
                            color="#F1705A"
                            marginTop="1.5rem"
                            sx={{
                              fontSize: { md: "30px", xs: "22px" },
                              fontWeight: { md: "bolder", xs: "bold" },
                            }}
                            textAlign="center"
                            alignItems="center"
                          >
                            {data?.whyCloudUpCollection?.items[1]?.title}
                          </Typography>
                          <Box
                            // paddingTop="1.5rem"
                            display="flex"
                            sx={{
                              flexDirection: { md: "column", xs: "column" },
                              // marginTop: { md: "3rem", xs: "3rem" },
                            }}
                          >
                            {data?.whyCloudUpCollection?.items[1]?.categoryItemsCollection?.items
                              ?.filter((item) => item != null)
                              .map((item) => (
                                <Box
                                  boxShadow="0 3px 3px 0 rgb(2 34 131 / 15%)"
                                  display="flex"
                                  padding="1rem"
                                  borderRadius="8px"
                                  marginY="8px"
                                  sx={{
                                    flexDirection: { md: "row", xs: "row" },
                                    marginBottom: {
                                      md: "2.5rem",
                                      xs: "2.5rem",
                                    },
                                  }}
                                  borderRight="5px solid 
                              #F19E38"
                                  bgcolor="white"
                                >
                                  <Box
                                    fontSize="15px"
                                    fontWeight="bold"
                                    marginRight="10px"
                                  >
                                    {item?.title}
                                  </Box>
                                  <Box fontSize="15px">{item?.description}</Box>
                                </Box>
                              ))}
                          </Box>
                        </Box>
                        <Box
                          position="absolute"
                          sx={{ display: { md: "block", xs: "block" } }}
                          left="41.5%"
                          width="14%"
                        >
                          <Box
                            mt="5px"
                            height="18rem"
                            width="100%"
                            component="img"
                            src={arrowWhycloudUp}
                            alt="Cloud Up LinkedIn"
                          />
                        </Box>
                        <Box
                          zIndex="1"
                          sx={{ width: { md: "45%", xs: "45%" } }}
                        >
                          {" "}
                          <Typography
                            marginTop="1.5rem"
                            color="#F1705A"
                            sx={{
                              fontSize: { md: "30px", xs: "22px" },
                              fontWeight: { md: "bolder", xs: "bold" },
                            }}
                            alignItems="center"
                            textAlign="center"
                          >
                            {data?.whyCloudUpCollection?.items[2]?.title}
                          </Typography>
                          <Box
                            // paddingTop="1.5rem"
                            display="flex"
                            sx={{
                              flexDirection: { md: "column", xs: "column" },
                            }}
                          >
                            {data?.whyCloudUpCollection?.items[2]?.categoryItemsCollection?.items
                              ?.filter((item) => item != null)
                              .map((item) => (
                                <Box
                                  boxShadow="0 3px 3px 0 rgb(2 34 131 / 15%)"
                                  display="flex"
                                  padding="1rem"
                                  borderRadius="8px"
                                  marginY="8px"
                                  sx={{
                                    flexDirection: { md: "row", xs: "row" },
                                    marginBottom: { md: "2rem", xs: "2rem" },
                                  }}
                                  borderLeft="5px solid #84D161"
                                  bgcolor="white"
                                >
                                  <Box
                                    fontSize="15px"
                                    fontWeight="bold"
                                    marginRight="10px"
                                  >
                                    {item?.title}
                                  </Box>
                                  <Box fontSize="15px">
                                    {" "}
                                    {item?.description}
                                  </Box>
                                </Box>
                              ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
      </Box>
    </>
  );
}
