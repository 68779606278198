import { gql } from "@apollo/client";
import { Document } from "@contentful/rich-text-types";

export const GetJobsQuery = gql`
  query jobCollectionQuery {
    jobCollection(order: sys_publishedAt_ASC) {
      items {
        sys {
          id
        }
        title
        summary {
          json
        }
        location
      }
    }
  }
`;

export interface JobItem {
  title: string;
  location: string;
  summary: {
    json: Document;
  };
}

export interface GetJobsResult {
  jobCollection: {
    items: JobItem[];
  };
}
