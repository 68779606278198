import { gql } from "@apollo/client";

export const GetLinkedInQuery = gql`
query keyFeaturesEntryQuery {
    keyFeatures(id: "3vToq0xXtuKkun9wbhQsVj") {
      sys {
        id
      }
      url
      title
    }
  }`;
export const GetEmailQuery = gql`
query keyFeaturesEntryQuery {
    keyFeatures(id: "2G6HnpHCuaW30SLwyF2sJl") {
      sys {
        id
      }
      title
      url
    }
  }`;
  export interface GetCarreContactResult {
    keyFeatures
: {
    title:string;
    url:string;
    };
  }
  