import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider, ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { errorLink } from "./utils/apollo-middleware";

const httpLink = new HttpLink({
  uri: "https://graphql.contentful.com/content/v1/spaces/g4oou9iaf9h8",
  headers: {
    Authorization: `Bearer tmlCeuGwBVlCx9jjKD9rfusSG7Os65wUVBuHva9bgK8`,
  },
})
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

const theme = createTheme({
  typography: {
    fontFamily: "Mulish",
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
