import { Typography, useMediaQuery } from "@mui/material";

export default function BannerDescription({
  description,
}: {
  description: string;
}) {
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");
  return (
    <Typography
      color="white"
      mt={1}
      width={isSmallScreen ? "90%" : "50%"}
      sx={{opacity:'1',fontSize:'15px' ,color: '#fff' ,textAlign:{md:"left",xs:"center"}, marginBottom
      :{md:"0px",xs:"1rem"} }}
    >
      {description}
    </Typography>
  );
}
