import { onError } from "@apollo/client/link/error";
export const errorLink = onError(
  ({ graphQLErrors, response, networkError, operation, forward }): any => {
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        const { extensions } = error as any;
        switch ((extensions as any).contentful.code) {
          case "UNRESOLVABLE_LINK": {
            return forward(operation).reduce(
              (resp: Record<string, any>, items) => ({
                ...resp,
                data: response?.data,
              }),
              {}
            );
            break;
          }
          case "INTERNAL_SERVER_ERROR":
          case "500": {
            console.error(
              `Error : ${error.message} \n for detail error see server log`
            );
            break;
          }
        }
      }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);
