import { FormControl, TextField } from "@mui/material";
import React from "react";
import styles from "./index.module.css";

export interface CustomInputProps {
  label: string;
}
export default function CustomInput({ label }: CustomInputProps) {
  return (
      <FormControl sx={{ width: "100%" }} size="small">
        <TextField
          className={styles["custom-input"]}
          id="outlined-size-small"
          size="small"
          sx={{
            borderRadius: "10px",
            background: "#fff",
            flexBasis: "98%",
            margin: "auto",
            backgroundColor: "#fff",
            "& fieldset": { border: "none!important" },
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            "& .MuiSelect-icon": {
              color: "#0474e5",
            },
            padding: "0.4rem"
          }}
          fullWidth
          label={label}
          placeholder={label}
        />
      </FormControl>
  );
}
