import { gql } from "@apollo/client";

export const GetOurExpertiseQuery = gql`
query ourExpertiseCollectionQuery{
  ourExpertiseCollection(order: order_ASC) {
    items {
         title
         order
         image{
          url
          title
        }
      categoryItemCollection (limit:50){
        items {
          title
             points
        }
      }
    }
  }
}`

export interface CategoryCollection {
  title: string;
  points: string;
}

export interface CompanyInformationImage {
  title: string;
   url: string;
 }
export interface OurExpertise {
  title: string;
  categoryItemCollection: { items: CategoryCollection[] };
  image
:CompanyInformationImage;
}
export interface GetOurExpertisetResult {
  ourExpertiseCollection: {
    items: OurExpertise[];
  };
}




