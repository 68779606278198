import { Box, Container, Typography } from "@mui/material";
import styles from "./index.module.css";
import Banner from "./components/banner";
import CaseStudy from "@/components/case-study";
import CompanyInformation from "./components/company-information";
import WhyCloudUpSection from "./components/service-section";
import AcceleratorLibrarySection from "./components/accerlerator-library";

const AboutPage = () => {
  return (
    <Box>
      <Banner page="about" />
      <WhyCloudUpSection />
      <AcceleratorLibrarySection />
      <Box className={styles.LatestProject}>
        <CaseStudy page="professional-services" />
      </Box>
      <CompanyInformation />
    </Box>
  );
};

export default AboutPage;
