import { Box, Container, styled, Typography } from "@mui/material";
import googleCloud from "@/assets/images/cloud_banner_2.png";
import OurExpertise from "./our-expertise";
import Solutions from "./solutions";
import ProjectSection from "@/components/project-section";
import CustomBanner from "@/components/custom-banner";
import BannerData from "@/components/banner-data/bannerData";

const BannerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const BannerContent = styled(Box)`
height:100%;
  display: flex;
  flex-direction: column;
  align-item:center:

  justify-content: center;
`;

const BannerImage = styled("img")`
height:100%;
  max-width: 55%;
  align-item:center:

  object-fit: contain;
`;

const BannerTitle = styled(Typography)`
  color: #ffffff;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 0.35rem;
  letter-spacing: 3px;
`;

const BannerDescription = styled(Typography)`
  color: #ffffff;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
`;

export default function GoogleCloudPlatform() {
  return (
    <Box>
      <BannerData HeadingId="4lyCmTbRYA2D4flmGcKVXd" />
      <OurExpertise page="" />
      <Solutions />
      <ProjectSection />
    </Box>
  );
}
