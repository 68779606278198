import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ROUTES } from "../../utils/constant";
import AboutPage from "@/pages/about";
import { Route } from "react-router-dom";
import { GetHeaderFragementQuery, GetHeaderFragementResult } from "@/model/GetHeaderFragement";
import { useQuery } from "@apollo/client";

export default function Hamburger() {
  const [open, setState] = useState(false);
  const HeaderFragment = useQuery(GetHeaderFragementQuery);
  const HeaderFragementData: GetHeaderFragementResult = HeaderFragment.data;
  const isBannerDataLoading: boolean = HeaderFragment.loading;
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const onClickNavigation = (url: string) => {
    window.location.href=url;

  };

  return (
    <>{!isBannerDataLoading && HeaderFragementData && (    <Box>
      <IconButton
        aria-label="open drawer"
        
        onClick={toggleDrawer(true)}
        sx={{
          
          mr: 2,
          display: "flex",
          justifyContent:"center",
          alignItems:"center",
          color: "white",
        }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            p: 2,
            height: 1,
            backgroundColor: "#fff",
          }}
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
            <CloseIcon />
          </IconButton>

          <Divider sx={{ mb: 2 }} />

          <Box sx={{ mb: 2 }}>
            {HeaderFragementData?.brands?.categoryItemsCollection?.items?.filter((item) => item != null).map((route) => (
              <ListItemButton
                key={route?.title}
                onClick={() => !!route?.url && onClickNavigation(route?.url)}
              >
                <ListItemText primary={route?.title} />
              </ListItemButton>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>)}</>

  );
}
