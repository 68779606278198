import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const GetDatasheetQuery = gql`
query dataCollectionQuery {
  dataCollection(order: sys_publishedAt_DESC) {
    items {

        title
        pdf{
          title
          url
        }
        description
        sheet {
          url
          title
        }
      }
  }
}
`;

export interface pdfLink{
title:string;
url:string;
}
export interface DatasheetItem {
  title: string;
  description: string;
  sheet: MediaItem;
  pdf:pdfLink;
}

export interface GetDatasheetResult {
  dataCollection
: {
    items: DatasheetItem[];
  }
}
