import CustomButton from "@/components/custom-button";
import CustomSelect from "@/components/custom-select";
import JobSummary from "@/components/job-summary";
import { useQuery } from "@apollo/client";
import { GetJobsQuery, GetJobsResult, JobItem } from "@/model/GetJobFragement";
import { Box, Container, Skeleton, styled } from "@mui/material";
import React, { useState, useEffect } from "react";

const SectionWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function JobSearch() {
  const [jobLocation, setJobLocation] = useState("all");
  const [jobTitle, setJobTitle] = useState("all");
  const [jobs, setJobs] = useState<JobItem[]>([]);
  const jobResult = useQuery(GetJobsQuery);
  const data: GetJobsResult = jobResult.data;
  const loading: boolean = jobResult.loading;

  useEffect(() => {
    if (jobResult) {
      // console.log("jobResult:", jobResult);
    } else {
      // console.log("Banner fragment data is not available");
    }
  });

  const searchJobs = () => {
    let resultJobs: JobItem[] = [];
    if (loading) resultJobs = [];
    else if (!data?.jobCollection?.items?.length) resultJobs = [];
    else if (jobTitle === "all" && jobLocation === "all")
      resultJobs = data?.jobCollection?.items;
    else {
      const matchedJobs = data?.jobCollection?.items.filter(
        (item) =>
          item?.title
            .toLowerCase()
            .includes(jobTitle === "all" ? "" : jobTitle) &&
          item?.location
            .toLowerCase()
            .includes(jobLocation === "all" ? "" : jobLocation)
      );
      resultJobs = matchedJobs;
    }
    setJobs(resultJobs);
  };

  useEffect(() => {
    if (loading) setJobs([]);
    else searchJobs();
  }, [loading]);

  return (
    <Box padding="3rem 0">
      {loading && (
        <Skeleton variant="rectangular" width="100%" height="300px" />
      )}

      <SectionWrapper>
        <Box
          display="flex"
          sx={{ flexDirection: { md: "row", xs: "column" } }}
          justifyContent="center"
          paddingTop={2}
          width="100%"
        >
          <Box
            flexBasis={"30%"}
            padding="0 1rem"
            display="flex"
            flexDirection={"column"}
            justifyContent="flex-end"
            sx={{ marginY: { md: "0px", xs: "0.5rem" } }}
          >
            <CustomSelect
              options={["All", "Remote"]}
              label="Search"
              value={jobLocation}
              onChange={(value: string) => setJobLocation(value)}
            />
          </Box>
          <Box
            flexBasis={"30%"}
            padding="0 1rem"
            display="flex"
            sx={{ marginY: { md: "0px", xs: "0.5rem" } }}
            flexDirection={"column"}
            justifyContent="flex-end"
          >
            <CustomSelect
              options={
                loading
                  ? []
                  : [
                      "All",
                      ...data?.jobCollection?.items?.filter((item) => item != null).map((item) => item.title),
                    ] || []
              }
              label="Jobs"
              onChange={(value: string) => setJobTitle(value)}
              value={jobTitle}
            />
          </Box>
          <Box
            flexBasis={"unset"}
            padding="0 1rem"
            sx={{ marginY: { md: "0px", xs: "0.5rem" } }}
            display="flex"
            flexDirection={"column"}
            justifyContent="flex-end"
          >
            <CustomButton title="Find Jobs" onClick={searchJobs} />
          </Box>
        </Box>
        {jobs.length > 0 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="2rem 0"
            margin="3rem 0"
            width="90%"
          >
            {jobs?.filter((item) => item != null).map((item, index) => (
              <JobSummary
                key={index}
                title={item?.title}
                location={item?.location}
                summary={item?.summary?.json}
              />
            ))}
          </Box>
        )}
      </SectionWrapper>
    </Box>
  );
}
