import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, styled, Link, Typography } from "@mui/material";
import arrowIcon from "@/assets/icons/arrow.svg";
import { Document } from "@contentful/rich-text-types";

const SummaryWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 1rem 0rem;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 70px 0 rgba(2, 34, 131, 0.15);
  background-color: #fff;
`;

const SummaryContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 75%;
`;

const SummaryLink = styled(Link)`
  font-weight: bold;
  color: #181818;
  padding: 0.8rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 30px;
  background-color: #eae8e8;
`;

const SummaryTitle = styled(Typography)`
  font-weight: bold;
  color: #181818;
  margin-bottom: 1rem;
`;

const SummaryRole = styled(Typography)`
  color: #181818;
  opacity: 0.83;
  margin-bottom: 0;
`;

export default function JobSummary({
  title,
  location,
  summary
}: {
  title: string;
  location: string;
  summary: Document
}) {
  const navigate = useNavigate();

  const onLinkClick = () => {
    window.scrollTo(0, 0)

    const format = title.toLowerCase().replaceAll(" ", "-");
    const link = `/job-openings/${format}`;
    navigate(link, { state: { title, location, summary}});
  }
  return (
    <SummaryWrapper sx={{flexDirection:{md:"row",xs:"column"}}}>
      <SummaryContent sx={{marginBottom:{md:"0rem",xs:"0.5rem"}}}>
        <SummaryTitle sx={{textAlign:{md:"left",xs:"center"},  fontSize:{sm:"32px",xs:"25px"},
}}>{title}</SummaryTitle>
        <SummaryRole sx={{textAlign:{md:"left",xs:"center"},  fontSize:{sm:"18px",xs:"15px"}
}}>{location}</SummaryRole>
      </SummaryContent>
      <SummaryLink
        onClick={onLinkClick}
        sx={{
          fontSize:"15px",
          cursor: 'pointer',
          "&:hover": {
            backgroundColor: "#F1705A",
            color: "#fff",
            "& .link-button-icon": {
              opacity: 1,
              transition: "0.3s",
              marginLeft: "0.5rem",
            },
          },
        }}
      >
        Read More
        <Box
          className="link-button-icon"
          component="img"
          src={arrowIcon}
          alt="arrow icon"
          sx={{ opacity: 0, marginLeft: 0, transition: "0.3s" }}
        />
      </SummaryLink>
    </SummaryWrapper>
  );
}
