import {
  AppBar,
  Box,
  Container,
  Link,
  styled,
  useMediaQuery,
} from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import React, { useEffect, useState } from "react";
import Cloudlogo from "../../assets/images/cloud_up_logo_white.svg";
import { ROUTES } from "../../utils/constant";
import Hamburger from "../hamburger";
import { useLocation } from "react-router-dom";
import { GetHeaderFragementQuery, GetHeaderFragementResult } from "@/model/GetHeaderFragement";
import { useQuery } from "@apollo/client";

const LinkItem = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  padding: 0rem 1rem;
  outline: none;
  height: unset;
  cursor: pointer;
`;

interface HeaderProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement;
}

export default function Header({ window }: HeaderProps) {
  const HeaderFragment = useQuery(GetHeaderFragementQuery);
  const HeaderFragementData: GetHeaderFragementResult = HeaderFragment.data;
  const isBannerDataLoading: boolean = HeaderFragment.loading;
  const location = useLocation();
  let [activeRoute, setActiveRoute] = useState("");
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment =
      pathSegments[pathSegments.length - 1] !== ""
        ? pathSegments[pathSegments.length - 1]
        : "about";
    setActiveRoute(lastSegment);
  }, [location, activeRoute]);

  const isWidthBigger1024: boolean = useMediaQuery("(min-width:1024px)");
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 40,
    disableHysteresis: true,
  });

  return (
    <React.Fragment>
      {!isBannerDataLoading && HeaderFragementData && (   <AppBar
        position="sticky"
        elevation={0}
        style={{
          backgroundImage: "linear-gradient(to right, #F1705A 0%, #F0AD56 100%",
        }}
      >
        <Box
          component="nav"
          display="flex"
          justifyContent="space-between"
          padding={trigger ? "1rem 0rem" : "1rem 0rem"}
          minWidth="100%"
          zIndex={2}
          sx={{
            transition: ".5s",
            outline: "none",
            opacity: "1",
            backgroundImage:"linear-gradient(to right, #F1705A 0%, #F0AD56 100%"
          }}
        >
          <Container maxWidth="xl">
            <Box
              display="flex"
              flexWrap="inherit"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{
                paddingX: {
                  xl: "6rem",
                  lg: "1rem",
                  md: "1rem",
                  sm: "1rem",
                  xs: "1rem",
                },
              }}
            >
              <Link href="/">
                <Box
                  width={80}
                  height={65}
                  component="img"
                  alt="logo"
                  src={HeaderFragementData?.brands?.logo?.url ? HeaderFragementData?.brands?.logo?.url : Cloudlogo}
                  sx={{
                    filter: trigger
                      ? "brightness(0) invert(1)"
                      : "brightness(0) invert(1)",
                  }}
                />
              </Link>
              {isWidthBigger1024 ? (
                <Box display="flex" alignItems="center">
      
                  {HeaderFragementData?.brands?.categoryItemsCollection?.items?.filter((item) => item != null).map((route, index) => {
                    const isActive =
                      activeRoute === route?.title?.toLocaleLowerCase() ||
                      (activeRoute === "google-cloud-platform" &&
                      (route?.title === "GOOGLE CLOUD" || route?.title === "Google Cloud"))
                        ? true
                        : false;
                    return (
                      
                      <Box key={index} mx={1}> 
                        <LinkItem
                          underline="none"
                          href={route.url}
                          sx={{
                            color: isActive ? "#fff" : "#fff",
                            backgroundColor: isActive ? "#ff6a4f" : "",
                            padding: isActive ? "0.5rem" : "0",
                            borderRadius: isActive ? "1rem" : "0",
                            fontWeight: isActive ? "900" : "",
                          }}
                        >
                          {route?.title?.toLocaleUpperCase()}
                        </LinkItem>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Hamburger />
              )}
            </Box>
          </Container>
        </Box>
      </AppBar>)}
   
    </React.Fragment>
  );
}
