import { Typography } from "@mui/material";
import React from "react";

export default function BannerTitle({ title }: { title: string }) {
  return (
    <Typography variant="h6" fontWeight="500" color="white" fontSize="2.25rem"  sx={{ textTransform: 'uppercase',paddingBottom:'1rem','@media (max-width: 881px)': {
      paddingBottom: 0,paddingTop:'1rem'
    }, }}>
      {title}
    </Typography>
  );
}
;