import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const dataAnalyticsOfferingsEntryQuery = gql`
  query dataAnalyticsOfferingsCollectionQuery {
    dataAnalyticsOfferingsCollection(order: order_ASC) {
      items {
        title
        categoryItemsCollection(limit: 20) {
          items {
            title
            description
            icon {
              title
              url
            }
          }
        }
      }
    }
  }
`;
export const CompanyWorkCompanyStaticsEntryQuery = gql`
  query dataAnalyticsOfferingsEntryQuery {
    dataAnalyticsOfferings(id: "2Hwdi92aZF7wNPD9vYBy5y") {
      title
      categoryItemsCollection(limit: 20) {
        items {
          title
          description
          icon {
            title
            url
          }
        }
      }
    }
  }
`;
export const CompanyWorkIndustriesWeWorkEntryQuery = gql`
  query dataAnalyticsOfferingsEntryQuery {
    dataAnalyticsOfferings(id: "45yorqKYh07GIwGnnugHWQ") {
      title
      categoryItemsCollection(limit: 20) {
        items {
          title
          description
          icon {
            title
            url
          }
        }
      }
    }
  }
`;

export interface CategoryCollection {
  title: string;
  description: string;
  icon: CompanyInformationImage;
}
export interface CompanyInformationImage {
  title: string;
  url: string;
}
export interface CompanyInformation {
  title: string;
  categoryItemsCollection: { items: CategoryCollection[] };
}
export interface GetCompanyInformationFragmentResult {
  dataAnalyticsOfferingsCollection: {
    items: CompanyInformation[];
  };
}
export interface GetCompanyInformationFragmentResultbyID {
  dataAnalyticsOfferings: CompanyInformation;
}
