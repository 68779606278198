import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  styled,
  Link,
  Typography,
  Divider,
} from "@mui/material";
import CustomHeader from "@/components/header";
import { ReactComponent as ArrowLeft } from "@/assets/icons/arrow-left.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CustomButton from "@/components/custom-button";
import emailIcon from "@/assets/icons/email.png";
import linkedinIcon from "@/assets/icons/linkedin.png";
import { useQuery } from "@apollo/client";
import { GetCarreContactResult, GetEmailQuery, GetLinkedInQuery } from "@/model/GetCarrerContactCategoryFragment";

const JobContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const JobDescriptionContainer = styled(Box)`
  flex-basis: 70%;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(2, 34, 131, 0.25);
  background-color: #fff;
  padding: 0.8rem 2.5rem;
`;

const BackLink = styled(Link)`
  font-size: 14px;
  color: #858585;
  text-decoration: none;
  outline: none;
  border: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  line-height: 1.43;
  margin-bottom: 0.2rem;
`;

const JobTitle = styled(Typography)`
  font-size: 30px;
  font-weight: bold;
  color: #0474e5;
  margin-bottom: 0.5rem;
`;

const JobLocation = styled(Typography)`
  color: #161515;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0rem;
  line-height: 1.44;
`;

const JobDetailContainer = styled(Box)`
  flex-basis: 26%;
`;

const JobApplicationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(2, 34, 131, 0.25);
  background-color: #fff;
  margin-bottom: 2rem;
`;

const JobInfoContainer = styled(Box)`
  border-bottom: solid 1px #b7b7b787;
  margin-bottom: 1.5rem;
`;

const JobLinkContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
`;

export default function JobDetail() {

  //for Linked in
  const LinkedInResult = useQuery(GetLinkedInQuery);
  const LinkedIndata: GetCarreContactResult = LinkedInResult.data ;
  console.log("LinkedInResult",LinkedIndata)
  const LinkedInloading: boolean = LinkedInResult.loading;

  //for email
  const EmailResult = useQuery(GetEmailQuery);
  const Emaildata: GetCarreContactResult = EmailResult.data ;
  const Emailloading: boolean = EmailResult.loading;
  const router = useLocation();
  const { title, location, summary } = router.state;
  return (
    <Box>
      <CustomHeader />
      <Box bgcolor="#f3f8fc">
        <JobContainer sx={{ flexDirection: { md: "row", xs: "column" } }}>
          <JobDescriptionContainer>
            <BackLink href={"/career"}>
              <Box mr={1}>
                <ArrowLeft />
              </Box>
              Current Openings
            </BackLink>
            <JobTitle>{title?title:""}</JobTitle>
            <JobLocation>{location?location:""}</JobLocation>
            <Divider />
            <Box color="#484848">{documentToReactComponents(summary)}</Box>
          </JobDescriptionContainer>
          <JobDetailContainer sx={{ marginTop: { md: "0rem", xs: "1rem" } }}>
            <JobApplicationContainer>
              <CustomButton title="Apply To This Job" onClick={() => {}} />
              {!Emailloading && Emaildata  && ( <JobLinkContainer href={Emaildata?.keyFeatures?.url} target="">
                <Box
                  component="img"
                  src={emailIcon}
                  alt="Cloud-Up Email"
                  mr="0.8em"
                  maxWidth="100%"
                  minHeight="100%"
                  sx={{ objectFit: "contain" }}
                />
                <Typography fontSize="16px" color="#484848">
               {Emaildata?.keyFeatures?.title}
                </Typography>
              </JobLinkContainer>)}
             {!LinkedInloading && LinkedIndata && (  <JobLinkContainer
               href={LinkedIndata?.keyFeatures?.url}
                target="_blank"
              >
                <Box
                  component="img"
                  src={linkedinIcon}
                  alt="Cloud-up LinkedIn"
                  mr="0.8em"
                  maxWidth="100%"
                  minHeight="100%"
                  sx={{ objectFit: "contain" }}
                />
                <Typography fontSize="16px" color="#484848" margin={0}>
                {LinkedIndata?.keyFeatures?.title}
                </Typography>
              </JobLinkContainer>)}
          
            </JobApplicationContainer>
            <JobInfoContainer>
              <Typography
                fontSize="18px"
                lineHeight={1}
                color="#181818"
                mb="0.5rem"
              >
                Location
              </Typography>
              <Typography
                fontSize="18px"
                fontWeight="bold"
                lineHeight={1.33}
                color="#161515"
                mb={1.5}
              >
                {location?location:""}
              </Typography>
            </JobInfoContainer>
          </JobDetailContainer>
        </JobContainer>
      </Box>
    </Box>
  );
}
