import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";
import IndustryContent from "@/components/industry-content";
import {
  GetProjectByIdQuery,
  GetProjectByIdResult,
} from "@/model/GetProjectFragement";

export default function CaseTabHeader({
  title,
  projectId,
}: {
  title: string;
  projectId: string;
}) {
  const projectByIdResult = useQuery(GetProjectByIdQuery, {
    variables: { entryId: projectId },
  });
  const data: GetProjectByIdResult = projectByIdResult.data;
  const loading: boolean = projectByIdResult.loading;
  return (
    <Box>
      <Box display="flex" sx={{flexDirection:{md:"row",xs:"column"}}} justifyContent="space-between" mb="1rem">
        <Typography
          flexBasis="48%"
          fontSize="25px"
          fontWeight="bold"
          color="#333"
          textAlign="left"
          mb={0}
        >
          {title}
        </Typography>
        {loading && (
          <Skeleton variant="rectangular" width="100%" height="50px" />
        )}
        {!loading && data.project && (
          <IndustryContent
            logo={data.project.logo}
            industry={data.project.industry}
            service={data.project.service}
          />
        )}
      </Box>
    </Box>
  );
}
