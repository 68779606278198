import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
export interface CustomSelectProps {
  label: string;
  options: string[];
  onChange: (value: string) => void;
  value: string;
}

export default function CustomSelect({
  label,
  options,
  onChange,
  value,
}: CustomSelectProps) {
  const [option, setOption] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={option}
        label={label}
        onChange={handleChange}
        sx={{
          backgroundColor: "#fff",
          borderRadius: '10px',
          "& fieldset": { border: "none!important" },
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          "& .MuiSelect-icon": {
            color: "#0474e5",
          },
          padding: "0.4rem"
        }}
        fullWidth
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: "20px",
              marginTop: "2px",
            },
          },
          MenuListProps: {
            sx: {
              "& li:focus": { backgroundColor: "transparent!important" },
            },
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.toLowerCase()}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
