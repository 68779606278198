import CustomBanner from "@/components/custom-banner";
import SectionTitle from "@/components/section-title";
import datasheetIcon from "../../assets/images/data.svg";
import {
  GetDatasheetQuery,
  GetDatasheetResult,
} from "@/model/GetDatasheetFragement";
import { useQuery } from "@apollo/client";
import { Box, Container, Skeleton, styled, Typography } from "@mui/material";
import SheetView from "./sheet-view";
import SectionHeading from "@/components/section-heading";
import BannerData from "@/components/banner-data/bannerData";



const BannerTitle = styled(Typography)`
  color: #2878f7;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 0.35rem;
  letter-spacing: 3px;
`;

const BannerDescription = styled(Typography)`
  color: #7288f8;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
`;
const BannerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: auto;
  position: relative;
  z-index: 1;
`;

const BannerContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BannerImage = styled("img")`
  max-width: 55%;
  object-fit: contain;
`;

export default function DataSheet() {
  const datasheetResult = useQuery(GetDatasheetQuery);
  const data: GetDatasheetResult = datasheetResult.data;
  const loading: boolean = datasheetResult.loading;
  return (
    <Box>
      <BannerData HeadingId="6yO8Tn4vSwpxjqQlMjGsXk"/>

      <Container maxWidth="xl" sx={{ my: 10 }}>
        <Box
          sx={{
            paddingX: {
              xl: "6rem",
              lg: "1rem",
              md: "1rem",
              sm: "1rem",
              xs: "1rem",
            },
          }}
        >
        <SectionHeading color="#3C4858" HeadingId="4Z8ojVe00Chod8Af8LkPef"></SectionHeading>

          <Box>
            {!loading && data && data.dataCollection && data.dataCollection.items.length > 0 ? (
              <Box
                display="flex"
                flexWrap="wrap"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { xs: "left", md: "center" },
                  textAlign: { xs: "center", md: "left" },
                }}
                justifyContent="space-between"
              >
                {data?.dataCollection?.items?.filter((item) => item != null).map((item, index) => (
                  <SheetView key={index} index={index} {...item} />
                ))}
              </Box>
            ):<Box display="flex" justifyContent="center" alignItems="center" minHeight="300px" fontSize="2rem" fontWeight="500">There is no Data Sheet</Box>}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
