import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const GetHeaderFragementQuery = gql`
  query brandsEntryQuery {
    brands(id: "1Ms0FcSEHpMzEYkW9qWVFN") {
      sys {
        id
      }
      logo {
        title
        url
      }
      categoryItemsCollection {
        items {
          title
          url
        }
      }

      # add the fields you want to query
    }
  }
`;

export interface CategoryCollection {
  title: string;
  url: string;
}
export interface GetHeaderFragementResult {
  brands: {
    logo: { title: string; url: string };
    categoryItemsCollection: { items: CategoryCollection[] };
  };

}
