import { useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Box, Button, Container, Link } from "@mui/material";
import { East, HorizontalRule, West } from "@mui/icons-material";
import file from "@/assets/images/fileCircle.svg";
import styles from "./index.module.css";
import { useQuery } from "@apollo/client";
import {
  GetLatestProjectQuery,
  GetLatestProjectResult,
} from "@/model/GetLatestProjectFragement";
import Skeleton from "@mui/material/Skeleton";
import SectionHeading from "../section-heading";
import HorizontalRuler from "../horizontal-ruler-heading";
import noImage from "@/assets/images/noImage.png"

export default function ProjectSection() {
  const latestProjects = useQuery(GetLatestProjectQuery);
  const data: GetLatestProjectResult = latestProjects.data;
  const loading: boolean = latestProjects.loading;

  useEffect(() => {
    if (latestProjects) {
      // console.log("latestProjects:", latestProjects);
    } else {
      // console.log("Banner fragment data is not available");
    }
  });

  const sliderRef: any = useRef(null);

  const handlePrev = () => {
    if (!sliderRef.current || !sliderRef.current.swiper) return;
    sliderRef.current?.swiper.slidePrev();
  };

  const handleNext = useCallback(() => {
    if (!sliderRef.current || !sliderRef.current.swiper) return;
    sliderRef.current?.swiper.slideNext();
  }, []);

  return (
    <>{!loading && data && data.latestProjectsCollection && data.latestProjectsCollection.items.length > 0 && (
    <Box className={styles.ProjectSectionWrapper}>
      <Container maxWidth="xl">
        <Box
          className={styles["projects-section"]}
          sx={{
            paddingX: {
              xl: "6rem",
              lg: "1rem",
              md: "1rem",
              sm: "1rem",
              xs: "1rem",
            },
          }}
        >
          <div className={styles["projects-container"]}>
            <SectionHeading
              color="#3C4858"
              HeadingId="6pkktmg040WF6zo8du03Ca"
            ></SectionHeading>
<HorizontalRuler/>
            <Box
              className={styles["projects-content"]}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",

                "& .swiper-slide": {
                  height: "auto!important",
                },
              }}
            >
              <Button
                className="prev"
                sx={{
                  position: { xs: "absolute", sm: "inherit" },
                  left: { xs: "0", sm: "" },
                  margin: { xs: "-0.5rem", sm: "" },
                  zIndex: { xs: "11", sm: "" },
                }}
                onClick={handlePrev}
              >
                <West
                  fontSize="large"
                  sx={{
                    color: "#8C8C8C",
                    backgroundColor: { xs: "#e6e3e3", sm: "transparent" },
                    borderRadius: { xs: "50%", sm: "0%" },
                  }}
                />
              </Button>
              
                <Swiper
                  onBeforeInit={(swiper) => {
                    sliderRef.current = swiper;
                  }}
                  breakpoints={{
                    576: {
                      // width: 576,
                      slidesPerView: 1,
                    },
                    768: {
                      // width: 768,
                      slidesPerView: 2,
                    },
                    978: {
                      // width : 978
                      slidesPerView: 3,
                    },
                    1298: {
                      slidesPerView: 4,
                    },
                  }}
                  centeredSlides={false}
                  loop
                  modules={[Navigation]}
                  navigation={{
                    prevEl: ".prev",
                    nextEl: ".next",
                  }}
                  style={{
                    padding: "0.5rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  {data?.latestProjectsCollection?.items?.filter((item) => item != null).map((item, index) => (
                    <SwiperSlide
                      key={index}
                      id={styles.industryCards}
                      className={styles["industry-wrapper"]}
                    >
                      <div className={styles["project-card"]}>
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            height="100%"
                            width="100%"
                          />
                        ) : (
                          <>
                            <div className={styles["card-image"]}>
                              <img
                                className={styles["card-image-style"]}
                                src={item?.icon?.url ? item?.icon?.url:noImage}
                              />
                            </div>
                            <div className={styles["card-content"]}>
                              <p className={styles["card-title"]}>
                                {item?.title}
                              </p>
                              <div className={styles["card-description"]}>
                                <p>{item?.description}</p>
                              </div>
                              <Box    sx={{display:"none"}}>            <Link
                           
                           fontSize="13px"
                           color="#F1705A"
                           className={styles["learn-more"]}
                           href={item?.link ?? "#"}
                         >
                           Read More
                         </Link></Box>
                  
                            </div>
                            <Box
                              sx={{ backgroundColor: "#ffffff" }}
                              className={styles["background-image"]}
                            />
                          </>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
      
              <Button
                className="next"
                sx={{
                  position: { xs: "absolute", sm: "inherit" },
                  right: { xs: "0", sm: "" },
                  margin: { xs: "-0.5rem", sm: "0rem" },
                  zIndex: { xs: "11", sm: "0" },
                }}
                onClick={handleNext}
              >
                <East
                  fontSize="large"
                  sx={{
                    color: "#8C8C8C",
                    backgroundColor: { xs: "#e6e3e3", sm: "transparent" },
                    borderRadius: { xs: "50%", sm: "0%" },
                  }}
                />
              </Button>
            </Box>
          </div>
        </Box>
      </Container>
    </Box>
            )}
    </>
  );
}
