import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const GetOurExpertiseQuery = gql`
  query ourExpertiseCollectionQuery {
    ourExpertiseCollection(order: order_ASC) {
      items {
        sys {
          id
        }
        title
        icon {
          url
          title
        }
      }
    }
  }
`;

export interface OurExpertiseItem {
  sys: {
    id: string;
  };
  title: string;
  icon: MediaItem;
}

export interface GetOurExpertiseResult {
  ourExpertiseCollection: {
    items: OurExpertiseItem[];
  };
}

export const GCPSolutionsQuery = gql`
  query gcpSolutionsCollectionQuery {
    gcpSolutionsCollection(order: sys_publishedAt_ASC) {
      items {
        sys {
          id
        }
        title
        description
      }
    }
  }
`;

export interface GCPSolution {
  sys: {
    id: string;
  };
  title: string;
  description: string;
}

export interface GCPSolutionsResult {
  gcpSolutionsCollection: {
    items: GCPSolution[];
  };
}

export const GCPSolutionContainerQuery = gql`
  query gcpSolutionContainerCollectionQuery {
    gcpSolutionContainerCollection(order: order_ASC) {
      items {
        sys {
          id
        }
        order
        title
        description
        icon {
          url
          title
        }
        solutionsCollection {
          items {
            sys {
              id
            }
          }
        }
      }
    }
  }
`;

export interface SolutionSysId {
  sys: {
    id: string;
  };
}

export interface GCPSolutionContainer {
  sys: {
    id: string;
  };
  title: string;
  description: string;
  icon: MediaItem;
  solutionsCollection: {
    items: SolutionSysId[];
  };
}

export interface GCPSolutionContainerResult {
  gcpSolutionContainerCollection: {
    items: GCPSolutionContainer[];
  };
}

export const GCPSolutionByIdQuery = gql`
  query gcpSolutionsEntryQuery($entryId: String!) {
    gcpSolutions(id: $entryId) {
      sys {
        id
      }
      title
      description
    }
  }
`;

export interface GCPSolutionByIdResult {
  gcpSolutions: {
    sys: {
      id: string;
    };
    title: string;
    description: string;
  };
}
