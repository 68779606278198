import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import CaseTabHeader from "../tab-header";

export default function ChallengeTab({
  title,
  projectId,
  richDocument,
  resources,
  media,
}: {
  title: string;
  projectId: string;
  richDocument: Document;
  resources: Document;
  media: string;
}) {
  return (
    <Box>
      <CaseTabHeader projectId={projectId} title={title} />
      <Box
        display="flex"
        sx={{ flexDirection: { md: "row", xs: "column" } }}
        width="100%"
        justifyContent="space-between"
      >
        <Box sx={{minHeight:{lg:"290px",md:"300px"}}} flexBasis={"48%"}>
          <Typography
            fontSize="18px"
            fontWeight="600"
            lineHeight={1.44}
            letterSpacing="0.72px"
            textAlign="left"
            color="
            #F1705A"
            marginBottom={2}
          >
            Challenge
          </Typography>
          <Box>{documentToReactComponents(richDocument)}</Box>
        </Box>
        <Box flexBasis={"48%"}>
          <Typography
            fontSize="18px"
            fontWeight="600"
            lineHeight={1.44}
            letterSpacing="0.72px"
            textAlign="left"
            color="#4f4e4e"
            marginBottom={2}
          ></Typography>
          <Box>{documentToReactComponents(resources)}</Box>
        </Box>
      </Box>
      <Box
        padding="0.5rem"
        component="img"
        src={media}
        width="100%"
        height="100%"
      />
    </Box>
  );
}
