import { gql } from "@apollo/client";

export const GetTechnologiesQuery = gql`
  query technologyCollectionQuery {
    technologyCollection {
      items {
        title
        description
      }
    }
  }
`;

export interface TechnologyItem {
  title: string;
  description: string;
}

export interface GetTechnologiesResult {
  technologyCollection: {
    items: TechnologyItem[];
  };
}
