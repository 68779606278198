import { gql } from "@apollo/client";

export const GetSectionHeadingById = gql`
query pageTitlesEntryQuery($entryId: String!) {
    pageTitles(id: $entryId) {
      sys {
        id
      }
      page
      name
      
    }
  }
`;

export interface SectionHeadingByIdResult {
    pageTitles: {
    sys: {
      id: string;
    };
    page: String;
    name: String;

  };
}

export interface SectionHeadingIDItem {
    pageTitles:{ items: SectionHeadingByIdResult[]}
}
