import { styled, Button } from "@mui/material";

export interface CustomButtonProps {
  title: string;
  onClick: () => void;
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #ffffff;
  text-decoration: none;
  border-radius: 10rem;
  padding: 0.4rem 1rem;
  font-weight: 600;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(55, 5, 153, 1) 72%,
    rgba(6, 9, 163, 1) 100%
  );
  transition: opacity 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
`;

export default function CustomButton({ title, onClick }: CustomButtonProps) {
  return (
    <StyledButton
      onClick={onClick}
      sx={{
        fontSize: "15px",
        background:
          "linear-gradient(to right, #F1705A 0%, #F0AD56 100%)",
        "&:hover": {
          background:
            "linear-gradient(to right, #F0AD56 0%, #F1705A 100%)",
        },
      }}
    >
      {title}
    </StyledButton>
  );
}
