import { gql } from "@apollo/client";
import { Document } from "@contentful/rich-text-types";
import { ProjectSysId } from "./GetProjectFragement";

export const GetCaseStudyFragementQuery = gql`
  query featuredCaseStudyCollectionQuery($page: String!) {
    featuredCaseStudyCollection(where: { page: $page }, limit: 1) {
      items {
        sys {
          id
        }
        title
        icon{
          url
        }
        challenge {
          json
        }
        solution {
          json
        }
        result {
          json
        }
        resources {
          json
        }
        project {
          sys {
            id
          }
        }
      }
    }
  }
`;

export interface CaseStudyDetail {
  resources:{
    json: Document;
  };
  icon:{
    url:string;
  }
  sys: {
    id: string;
  };
  title: string;
  challenge: {
    json: Document;
  };
  solution: {
    json: Document;
  };
  result: {
    json: Document;
  };
  project: {
    sys: ProjectSysId;
  };
}

export interface GetCaseStudyFragementResult {
  featuredCaseStudyCollection: {
    items: CaseStudyDetail[];
  };
}
