import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import { Box } from "@mui/material";
import * as React from "react";
import { MediaItem } from "@/model/GetMediaItemFragement";

export default function IndustryContent({
  logo,
  industry,
  service,
}: {
  logo: MediaItem;
  industry: string;
  service: string;
}) {
  return (
    <Box
      display="flex"
      sx={{flexDirection:{sm:"row",xs:"column"}}}
      justifyContent="space-evenly"
      alignItems="center"
      flexBasis={'48%'}
      p={2}
      bgcolor="#fff5e5"
    >
      <Box  paddingY="0.8rem"  sx={{width:{sm:"15%",xs:"100%"}}} display="flex" ><Box component="img" sx={{width:{sm:"100%",xs:"30%"}}} src={logo.url} alt={logo.title}  mr={2} /></Box>
      <Box  display="flex"
      justifyContent="space-between"
      alignItems="center">
      <InfoComponent title="Industry" description={industry} />
      <InfoComponent title="AI Engineering" description={service} /></Box>
    </Box>
  );
}

const InfoTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  letter-spacing: 0.64px;
  color: #333;
  text-align: left;
`;

const InfoContent = styled(Typography)`
  color: #484848;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.5;
  letter-spacing: 0.64px;
  text-align: left;
`;

export function InfoComponent({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Box mx={1}>
      <InfoTitle>{title}</InfoTitle>
      <InfoContent>{description}</InfoContent>
    </Box>
  );
}
