import { useQuery } from "@apollo/client";
import { styled } from "@mui/material";
import { Typography } from "@mui/material";
import { Tabs } from "@mui/material";
import { Container } from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as React from "react";
import SectionTitle from "@/components/section-title";
import {
  GetCaseStudyFragementQuery,
  GetCaseStudyFragementResult,
} from "@/model/GetCaseStudyFragement";
import { Skeleton } from "@mui/material";
import ChallengeTab from "./challenge-tab";
import SolutionTab from "./solution-tab";
import SectionHeading from "../section-heading";
import HorizontalRuler from "../horizontal-ruler-heading";

const CaseStudySection = styled("section")`
  padding-bottom: 100px;
`;

const CaseStudyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CaseStudy({ page }: { page: string }) {
  const caseStudyQueryResult = useQuery(GetCaseStudyFragementQuery, {
    variables: { page },
  });
  const data: GetCaseStudyFragementResult = caseStudyQueryResult.data;
  const loading: boolean = caseStudyQueryResult.loading;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (<> 
  {!loading && data && data.featuredCaseStudyCollection && data.featuredCaseStudyCollection.items.length > 0 && (
  <>    <CaseStudySection>
      <CaseStudyContainer
        maxWidth="xl"
        sx={{
          paddingX: {
            xl: "8rem",
            lg: "2.5rem",
            md: "2.5rem",
            sm: "2.5rem",
            xs: "2rem",
          },
        }}
      >
        <SectionHeading
          color="#3C4858"
          HeadingId="3L5PqK9QoBXxSMUPnM1mce"
        ></SectionHeading>
         <HorizontalRuler/>
        <Box
          sx={{
            minHeight: { lg: "480px", md: "530px", xs: "auto" },
            backgroundColor: " #ffffff",
            borderRadius: "10px",
            width: "100%",
            boxShadow: "0 20px 40px 0 rgb(2 34 131 / 15%)",
            padding: 3,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#F1705A"

                }
              }}
              textColor="inherit"
              sx={{color:"#F1705A"}}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Challenge" {...a11yProps(0)}    />
              <Tab label="Solution & Result" {...a11yProps(1)}    />
            </Tabs>
          </Box>
          {loading && (
            <Skeleton variant="rectangular" width="100%" height="150px" />
          )}
          {!loading && data?.featuredCaseStudyCollection?.items?.length > 0 && (
            <>
              <TabPanel value={value} index={0}>
                <ChallengeTab
                  title={data.featuredCaseStudyCollection.items[0]?.title}
                  projectId={
                    data.featuredCaseStudyCollection.items[0]?.project?.sys?.id
                  }
                  richDocument={
                    data.featuredCaseStudyCollection.items[0]?.challenge?.json
                  }
                  resources={
                    data.featuredCaseStudyCollection.items[0]?.resources?.json
                  }
                  media={
                    data.featuredCaseStudyCollection.items[0]?.icon?.url }
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SolutionTab
                  title={data.featuredCaseStudyCollection.items[0]?.title}
                  projectId={
                    data.featuredCaseStudyCollection.items[0]?.project?.sys?.id
                  }
                  solutionRichDocument={
                    data.featuredCaseStudyCollection.items[0]?.solution?.json
                  }
                  resultRichDocument={
                    data.featuredCaseStudyCollection.items[0]?.result?.json
                  }
                  media={
                    data.featuredCaseStudyCollection.items[0]?.icon?.url
                  }
                />
              </TabPanel>
            </>
          )}
        </Box>
      </CaseStudyContainer>
    </CaseStudySection></>)}</>

  );
}
