import {
  Box,
  Container,
  Divider,
  Fab,
  Link,
  Typography,
  useScrollTrigger,
  Zoom,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { styled } from "@mui/system";
import { useCallback, useMemo } from "react";
import { KeyboardArrowUp } from "@mui/icons-material";
import rocketIcon from "@/assets/icons/rocket-icon.png";
import rocketLogo from "@/assets/images/rokectlogo.png";
import { useLocation } from "react-router-dom";
import ContactUs from "../contact-us";
import styles from "./footer.module.css";
import {
  GetConnectWithUsFooterQuery,
  GetFooterFragmentResult,
  GetFooterFragmentResultByID,
  GetFooterQuery,
  GetOurCompanyFooterQuery,
  GetRegionalOfficesFooterQuery,
  GetPrivacyFooterQuery,
  GetCopyrightFooterQuery,
  GetContactUsFooterQuery,
  GetSubscribeUsFooterQuery,
} from "@/model/GetFooterFragement";
const Wrapper = styled("section")``;
const FooterWrapper = styled("section")`
  padding: 5rem 0 0;
  background-color: #000000;
  overflow: hidden;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const MenuContainer = styled(Container)`
  display: flex;
  margin-bottom: 3rem;
`;

const BgPolygon = styled(Box)`
  position: absolute;
  width: 100%;
  height: 64%;
  background-color: #27283c;
  bottom: 0;
  left: 0;
  z-index: -2;
  clip-path: polygon(21% 28%, 100% 38%, 100% 100%, 0 100%, 0% 38%);
`;

const ContactUsContainer = styled(Box)`
  flex-basis: 40%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
`;

const RegionalContainer = styled(Box)`
  flex-basis: 30%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
`;

const SubscribeContainer = styled(Box)`
  flex-basis: 33%;
  padding: 2rem 0;
`;

const FooterRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const RowTitle = styled(Typography)`
  font-size: 22px;
  color: #fff;
  margin-bottom: 2rem;
`;

const RowIcon = styled("img")`
  margin-right: 0.5rem;
  height: fit-content;
`;

const NavMenu = styled("aside")`
  margin: 0;
`;

const MenuList = styled("ul")`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const MenuListItem = styled("li")`
  margin-bottom: 1rem;
  color: #787b98;
  font-size: 14px;
`;

const ScribeBox = styled(Box)`
  background-color: transparent;
  margin-bottom: 3rem;
  border-bottom: solid 1px #8a8ca2;
  display: flex;
  justify-content: space-between;
  width: 70%;
`;

const ScribeInput = styled("input")`
  background-color: #d9d9d9;
  outline: none;
  border: none;
  color: #fbfbf8;
  font-size: 14px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 3px;
`;

const ScribeImage = styled("img")`
  background-color: black;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
`;

const SocialLink = styled(Link)`
  width: 35px;
  height: 35px;
  color: #4169e1;
`;

const CopyrightText = styled(Typography)`
  margin-bottom: 0;
  color: #787b98;
  font-size: 13px;
`;

const PrivacyText = styled(Typography)`
  text-decoration: none;
  color: #ff9800;
  font-size: 14px;
`;

const AboutPages = ["/", "/about"];

export default function Footer() {
  const location = useLocation();
  const FooterResult = useQuery(GetFooterQuery);
  const data: GetFooterFragmentResult = FooterResult.data;
  const loading: boolean = FooterResult.loading;

  const RegionalOfficesFooterResult = useQuery(GetRegionalOfficesFooterQuery);
  const RegionalOfficesdata: GetFooterFragmentResultByID =
    RegionalOfficesFooterResult.data;
  const RegionalOfficesloading: boolean = RegionalOfficesFooterResult.loading;

  const OurCompanyFooterResult = useQuery(GetOurCompanyFooterQuery);
  const OurCompanydata: GetFooterFragmentResultByID =
    OurCompanyFooterResult.data;
  const OurCompanyloading: boolean = OurCompanyFooterResult.loading;

  const ConnectWithUsFooterResult = useQuery(GetConnectWithUsFooterQuery);
  const ConnectWithUsdata: GetFooterFragmentResultByID =
    ConnectWithUsFooterResult.data;
  const ConnectWithUsloading: boolean = ConnectWithUsFooterResult.loading;

  const PrivacyFooterResult = useQuery(GetPrivacyFooterQuery);
  const Privacydata: GetFooterFragmentResultByID = PrivacyFooterResult.data;
  const Privacyloading: boolean = PrivacyFooterResult.loading;

  const ContactUsFooterResult = useQuery(GetContactUsFooterQuery);
  const ContactUsdata: GetFooterFragmentResultByID = ContactUsFooterResult.data;
  const ContactUsloading: boolean = ContactUsFooterResult.loading;

  const SubscribeUsFooterResult = useQuery(GetSubscribeUsFooterQuery);
  const SubscribeUsdata: GetFooterFragmentResultByID =
    SubscribeUsFooterResult.data;
  const SubscribeUsloading: boolean = SubscribeUsFooterResult.loading;

  const CopyrightFooterResult = useQuery(GetCopyrightFooterQuery);
  const Copyrightdata: GetFooterFragmentResultByID = CopyrightFooterResult.data;
  const Copyrightloading: boolean = CopyrightFooterResult.loading;

  const scrollTrigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const renderContainerByPage = () => {
    const { pathname } = location;
    if (pathname.includes("about") || pathname === "/") return <ContactUs />;
    return <></>;
  };

  const isAboutPage = useMemo(() => {
    return AboutPages.indexOf(location.pathname) !== -1;
  }, [location.pathname]);

  return (
    <>
      {!loading && data && data.rightTalentCollection.items.length > 0 && (
        <>
          {" "}
          <Wrapper>{renderContainerByPage()} </Wrapper>
          <Box bgcolor="#000000">
            <Container maxWidth="xl">
              {!loading &&
                data &&
                data.rightTalentCollection &&
                data.rightTalentCollection.items.length > 0 && (
                  <FooterWrapper>
                    <FooterContainer className={styles.footerContainer}>
                      {!ContactUsloading &&
                        ContactUsdata &&
                        ContactUsdata?.rightTalent && (
                          <ContactUsContainer>
                            <RowTitle
                              sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "left" },
                              }}
                            >
                              {ContactUsdata?.rightTalent?.title}
                            </RowTitle>
                            {ContactUsdata?.rightTalent?.categoryItemsCollection?.items
                              ?.filter((item) => item != null)
                              .map((item) => (
                                <FooterRow
                                  sx={{
                                    display: "flex",
                                    justifyContent: {
                                      xs: "center",
                                      md: "left",
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "60%",
                                      display: "flex",
                                      justifyContent: {
                                        xs: "center",
                                        md: "left",
                                      },
                                      alignItems: "center",
                                      textAlign: { xs: "center", md: "left" },
                                    }}
                                  >
                                    <RowIcon
                                      src={item?.icon?.url}
                                      alt="Phone Number"
                                    />
                                    <Link
                                      // margin-bottom: 0rem;
                                      href={item?.url}
                                      sx={{
                                        lineHeight: "1.43",
                                        display: "flex",
                                        justifyContent: {
                                          xs: "center",
                                          md: "left",
                                        },
                                        color: "#787B98",
                                        textDecoration: "none",
                                        outline: "none!important",
                                        fontSize: "14px",
                                        "&:hover": {
                                          color: "#52a8ff",
                                        },
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description,
                                        }}
                                      />{" "}
                                    </Link>
                                  </Box>
                                </FooterRow>
                              ))}
                          </ContactUsContainer>
                        )}

                      {!RegionalOfficesloading &&
                        RegionalOfficesdata &&
                        RegionalOfficesdata.rightTalent &&
                        RegionalOfficesdata.rightTalent.title && (
                          <RegionalContainer
                            sx={{
                              alignItems: { md: "baseline", xs: "center" },
                            }}
                          >
                            <NavMenu>
                              <RowTitle
                                sx={{
                                  display: "flex",
                                  justifyContent: { xs: "center", md: "left" },
                                }}
                              >
                                {RegionalOfficesdata?.rightTalent?.title}
                              </RowTitle>
                              <Box>
                                <MenuList>
                                  {RegionalOfficesdata?.rightTalent?.categoryItemsCollection?.items
                                    ?.filter((item) => item != null)
                                    .map((item, index) => (
                                      <MenuListItem key={index}>
                                        <Link
                                          href={item?.url}
                                          sx={{
                                            display: "flex",
                                            justifyContent: {
                                              xs: "center",
                                              md: "left",
                                            },
                                            color: "#787B98",
                                            textDecoration: "none",
                                            outline: "none!important",
                                            fontSize: "14px",
                                            "&:hover": {
                                              color: "#52a8ff",
                                            },
                                          }}
                                        >
                                          {item?.title}
                                        </Link>
                                      </MenuListItem>
                                    ))}
                                </MenuList>
                              </Box>
                            </NavMenu>
                          </RegionalContainer>
                        )}
                      {!OurCompanyloading &&
                        OurCompanydata &&
                        OurCompanydata.rightTalent && (
                          <RegionalContainer>
                            <RowTitle
                              sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "left" },
                              }}
                            >
                              {OurCompanydata?.rightTalent?.title}
                            </RowTitle>
                            <MenuList>
                              {OurCompanydata?.rightTalent?.categoryItemsCollection?.items
                                ?.filter((item) => item != null)
                                .map((item) => (
                                  <MenuListItem
                                    sx={{
                                      display: "flex",
                                      justifyContent: {
                                        xs: "center",
                                        md: "left",
                                      },
                                    }}
                                  >
                                    <Link
                                      sx={{
                                        color: "#787B98",
                                        "&:hover": {
                                          color: "#52a8ff",
                                        },
                                      }}
                                      underline="none"
                                      href={item?.url}
                                    >
                                      {" "}
                                      {item?.title}
                                    </Link>
                                  </MenuListItem>
                                ))}
                            </MenuList>
                          </RegionalContainer>
                        )}

                      <SubscribeContainer>
                        {!SubscribeUsloading &&
                          SubscribeUsdata &&
                          SubscribeUsdata.rightTalent && (
                            <>
                              <RowTitle
                                sx={{
                                  display: "flex",
                                  justifyContent: { xs: "center", md: "left" },
                                }}
                              >
                                {SubscribeUsdata?.rightTalent?.title}
                              </RowTitle>
                              <ScribeBox
                                sx={{
                                  display: "flex",
                                  justifyContent: { xs: "center", md: "left" },
                                  width: { xs: "100%" },
                                  border: { xs: "none" },
                                }}
                              >
                                <ScribeInput
                                  type="text"
                                  sx={{
                                    paddingLeft: "0.8rem",
                                    color: "#000000",
                                    display: "flex",
                                    fontSize: "11px",
                                    justifyContent: {
                                      xs: "center",
                                      md: "left",
                                    },
                                  }}
                                  name="email"
                                  placeholder="Write Your Email Here..."
                                />
                                <ScribeImage
                                  sx={{
                                    backgroundColor: "#2b2929",
                                    display: "flex",
                                    justifyContent: {
                                      xs: "center",
                                      md: "left",
                                    },
                                    height: "32px",
                                    width: "37px",
                                    padding: "9px",
                                  }}
                                  src={rocketLogo}
                                  alt="Write Your Email Here"
                                />
                              </ScribeBox>
                            </>
                          )}

                        {!ConnectWithUsloading &&
                          ConnectWithUsdata &&
                          ConnectWithUsdata.rightTalent && (
                            <>
                              {" "}
                              <RowTitle
                                sx={{
                                  display: "flex",
                                  justifyContent: {
                                    xs: "center",
                                    md: "left",
                                  },
                                }}
                              >
                                {ConnectWithUsdata?.rightTalent?.title}
                              </RowTitle>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: {
                                    xs: "center",
                                    md: "left",
                                  },
                                }}
                              >
                                {ConnectWithUsdata?.rightTalent?.categoryItemsCollection?.items
                                  ?.filter((item) => item != null)
                                  .map((item) => (
                                    <SocialLink
                                      href={item?.url}
                                      marginRight="1.5rem"
                                      target="_blank"
                                    >
                                      <Box
                                        height="35px"
                                        component="img"
                                        src={item?.icon?.url}
                                        alt=""
                                      />
                                    </SocialLink>
                                  ))}
                              </Box>
                            </>
                          )}
                      </SubscribeContainer>
                    </FooterContainer>
                    <MenuContainer
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "left" },
                        flexDirection: { xs: "column", md: "row" },
                        marginX: "0rem",
                        paddingX: {
                          xl: "6rem",
                          md: "1rem",
                          sm: "1rem",
                          xs: "1rem",
                        },
                      }}
                    >
                      {!Copyrightloading &&
                        Copyrightdata &&
                        Copyrightdata.rightTalent && (
                          <>
                            {" "}
                            <CopyrightText
                              sx={{ textAlign: { xs: "center", md: "left" } }}
                            >
                              {Copyrightdata?.rightTalent?.title}
                            </CopyrightText>
                          </>
                        )}
                      {!Copyrightloading &&
                        Copyrightdata &&
                        Copyrightdata.rightTalent &&
                        !Privacyloading &&
                        Privacydata &&
                        Privacydata.rightTalent &&
                        Privacydata.rightTalent.title && (
                          <Divider
                            sx={{
                              display: { xs: "none", md: "inline" },
                              mx: 2,
                              border: "solid 1px #3c3e58",
                              backgroundColor: "#3c3e58",
                              width: "1px",
                            }}
                          />
                        )}
                      {!Privacyloading &&
                        Privacydata &&
                        Privacydata.rightTalent &&
                        Privacydata.rightTalent.title && (
                          <Link>
                            <PrivacyText
                              sx={{ textAlign: { xs: "center", md: "left" } }}
                            >
                              {Privacydata?.rightTalent?.title}
                            </PrivacyText>
                          </Link>
                        )}
                    </MenuContainer>
                    <BgPolygon
                      sx={{
                        clipPath: isAboutPage
                          ? "polygon(21% 28%, 100% 38%, 100% 100%, 0 100%, 0% 38%)"
                          : "polygon(51% 28%, 100% 38%, 100% 100%, 0 100%, 0% 38%)",
                        height: isAboutPage ? "64%" : "122%",
                      }}
                    ></BgPolygon>
                    <Zoom in={scrollTrigger}>
                      <Box
                        onClick={scrollToTop}
                        role="presentation"
                        sx={{
                          position: "fixed",
                          bottom: 32,
                          right: 32,
                          zIndex: 1,
                        }}
                      >
                        <Fab
                          size="medium"
                          aria-label="scroll back to top"
                          sx={{
                            backgroundColor: "#888888",
                            "&:hover": {
                              backgroundColor: "#888888",
                            },
                          }}
                        >
                          <KeyboardArrowUp
                            fontSize="large"
                            sx={{ color: "#EAEAEA" }}
                          />
                        </Fab>
                      </Box>
                    </Zoom>
                  </FooterWrapper>
                )}
            </Container>
          </Box>
        </>
      )}
    </>
  );
}
