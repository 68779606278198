import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const GetFooterQuery = gql`
query rightTalentCollectionQuery {
    rightTalentCollection(order: order_ASC) {
      items {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            }
          }
        }
      }
    }
  }
`;
export const GetRegionalOfficesFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "4hQWc9M4dQDcfOiYjj5NsH") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetOurCompanyFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "7aAaLd4zh5hc31mvcRWumv") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetConnectWithUsFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "4PcMuWcOIV5FgEa8smR06P") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetPrivacyFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "1u028Y6oODHS6vIyjKBQTN") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetContactUsFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "4t9Oyl0UUrLBkKCoEReeCJ") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetSubscribeUsFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "2UNWhWtK04ZN6iy5OLwta1") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;
export const GetCopyrightFooterQuery =gql`
query rightTalentEntryQuery {
    rightTalent(id: "66azFbNtgrNbD15ucafbRT") {
        title
        categoryItemsCollection(limit:20){
          items{
            title
            description
            url
            icon{
              url
            } 
        }
      }
    }
  }`;

export interface CategoryCollection {
  title: string;
  description: string;
  icon:ServiceImage;
  url:string
}
export interface ServiceImage {
  url: string;
}
export interface Footer {
  title: string;
  categoryItemsCollection: { items: CategoryCollection[] };
}
export interface GetFooterFragmentResult {
    rightTalentCollection: {
    items: Footer[];
  };
}
export interface GetFooterFragmentResultByID {
  rightTalent: Footer;
}
