import { useQuery } from "@apollo/client";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  GetBannerFragmentQuery,
  GetBannerFragmentResult,
} from "@/model/GetBannerFragment";
import { styled } from "@mui/material";
import styles from "./index.module.css";
import CustomBanner from "@/components/custom-banner";
import WhatWeDo from "./what-we-do";
import { useEffect } from "react";
const SwiperContainer = styled(Swiper)``;

export default function Banner({ page }: { page: any }) {
  const bannerFragment = useQuery(GetBannerFragmentQuery, {
    variables: { page },
  });
  const bannerFragementData: GetBannerFragmentResult =
    bannerFragment.data;
  const isBannerDataLoading: boolean = bannerFragment.loading;

  useEffect(() => {
    if (bannerFragment) {
      // console.log("Banner:", bannerFragment);
    } else {
      // console.log("Banner fragment data is not available");
    }
  });
  let swiper: any; // Define swiper variable
  const getDataByIndex = (index: number) => {
    return bannerFragementData.bannerCollection.items[index];
  };
  return (
    
      <>        {!isBannerDataLoading && bannerFragementData  && bannerFragementData.bannerCollection && bannerFragementData.bannerCollection.items.length > 0 &&(

      <CustomBanner>
          <SwiperContainer
            onSwiper={(instance) => {
              swiper = instance;
            }}
            modules={[Pagination, Navigation]}
            sx={{
              paddingBottom: { xs: "7rem", md: "0rem" },
              "& .swiper-pagination": {
                position: "absolute !important",
                bottom: "22px",
                display: "flex",
                left: "-31px",
              },
              "@media (max-width: 899px)": {
                "& .swiper-pagination": {
                  display: "flex",
                  justifyContent: "center",
                  bottom: "-1.6rem",
                  margin: "1rem",
                },
              },
            }}
          >
            {bannerFragementData?.bannerCollection?.items?.length>1 &&(
            <div className={styles["swiper-navigation"]}>
              <button
                className={styles["swiper-prev-button"]}
                onClick={() => swiper.slidePrev()}
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 64 64"
                  opacity="0.6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="32"
                    cy="32"
                    r="31"
                    transform="matrix(-1 0 0 1 64 0)"
                    stroke="white"
                    stroke-width="2"
                  />
                  <path
                    d="M36 20L24.56 31.44L35.12 42"
                    stroke="white"
                    stroke-width="1.5"
                  />
                </svg>
              </button>
              <button
                className={styles["swiper-next-button"]}
                onClick={() => swiper.slideNext()}
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 64 64"
                  opacity="0.6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="32"
                    cy="32"
                    r="31"
                    stroke="white"
                    stroke-width="2"
                  />
                  <path
                    d="M28 20L39.44 31.44L28.88 42"
                    stroke="white"
                    stroke-width="1.5"
                  />
                </svg>
              </button>
            </div>)}
            {bannerFragementData.bannerCollection?.items?.map((item, index) => (
              <SwiperSlide key={index}>
                <WhatWeDo
                  title={item?.title}
                  description={item?.description}
                  images={item?.imagesCollection?.items}
                  length={item?.imagesCollection?.items?.length}
                  name={item?.name}
                />
              </SwiperSlide>
            ))}
          </SwiperContainer>
          </CustomBanner> )}
      </>
  
  );
}
