
import { GetOurExpertiseQuery } from "@/model/GetOurExpertiseeFragment";
import { useQuery } from "@apollo/client";
import { Box, Container, Typography, styled } from "@mui/material";
import { Tabs } from "@mui/material";
import cloudlogo from "@/assets/images/cloud_banner_2.png";
import Tab from "@mui/material/Tab";
import SectionHeading from "@/components/section-heading";
import { GetOurExpertisetResult } from "@/model/GetOurExpertiseeFragment";
import React, { useEffect } from "react";
import styles from "../our-expertise/list.module.css";
import HorizontalRuler from "@/components/horizontal-ruler-heading";
import noImage from "@/assets/images/noImage.png";

const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TabContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  valueMain?: number;
}
const COLOR_MAP = ["#F1705A", "#F8842D", "#FF9800"];
const BG_COLOR_MAP = ["#ffb8a0", "#ffc28c", "#ffc97e"];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, valueMain, ...other } = props;

  return (
    <div
      className={styles.pointCss}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tab-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          bgcolor="#FFF5E5"
          borderRadius="1.5rem"
          display="flex"
          fontSize="1.65rem"
          alignItems="center"
          width="100%"
          sx={{ p: 3 }}
        >
          <Typography fontSize="1.65rem">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function OurExpertise({ page }: { page: string }) {
  const ourExpertiseResult = useQuery(GetOurExpertiseQuery);
  const data: GetOurExpertisetResult =
    ourExpertiseResult.data;
  const loading: boolean = ourExpertiseResult.loading;
  const [value, setValue] = React.useState(0);
  const [valueMain, setvalueMain] = React.useState(0);
  const [hovered, setHovered] = React.useState(false);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleChange = (newValue: number, index: number) => {
    setValue(newValue);
    setvalueMain(index);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (data) {
      const incrementValue = () => {
        if (!hovered) {
          if (
            value <
            data?.ourExpertiseCollection?.items[valueMain]?.categoryItemCollection
              .items.length -
              1
          ) {
            setValue(value + 1);
          } else if (valueMain < data?.ourExpertiseCollection?.items.length - 1) {
            setValue(0);
            setvalueMain(valueMain + 1);
          } else {
            setValue(0);
            setvalueMain(0);
          }
        }
      };
      interval = setInterval(incrementValue, 1500);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [data, value, valueMain, setValue, valueMain, hovered]);

  return (
    <>
      {!loading &&
        data &&
        data.ourExpertiseCollection &&
        data.ourExpertiseCollection.items.length > 0 && (
          <Box
            paddingBottom="100px"
            bgcolor="
    #F6F7FD"
          >
            <Container maxWidth="xl">
              {!loading && data && (
                <SectionContainer className={styles.heightForBox}>
                  <SectionHeading
                    color="#3C4858"
                    HeadingId="3ZDf48GxptTdzhRjJsBqt4"
                  ></SectionHeading>
                  <HorizontalRuler />
                  <Box
                    paddingBottom="2.5rem"
                    display="flex"
                    justifyContent="center"
                  >
                    {data &&
                      data?.ourExpertiseCollection?.items
                        .filter((item) => item != null)
                        .map((obj, index) => (
                          <Box
                            sx={{ marginY: { sm: "0rem", xs: "1rem" } }}
                            textAlign="center"
                          >
                            <Box
                              sx={{
                                height: { sm: "50px", xs: "25px" },
                                marginX: { sm: "3rem", xs: "2rem" },
                              }}
                              component="img"
                              src={obj?.image?.url ? obj?.image?.url : noImage}
                            ></Box>
                            <Box>{obj?.image?.title}</Box>
                          </Box>
                        ))}
                  </Box>
                  <Box
                    className={styles.OurExpertiseScale}
                    borderRadius="10px"
                    overflow="hidden"
                    sx={{
                      backgroundColor: " #ffffff",
                      width: "100%",
                      boxShadow: "0 20px 40px 0 rgb(2 34 131 / 15%)",
                    }}
                  >
                    <Box
                    bgcolor="#fff"
                      borderRadius="10px"
                      minHeight={
                        data?.ourExpertiseCollection?.items?.length ===
                        1
                          ? "500px"
                          : data?.ourExpertiseCollection?.items?.length === 2
                          ? "500px"
                          : "auto"
                      }
                      height={
                        data?.ourExpertiseCollection?.items?.length ===
                        1
                          ? "500px"
                          : data?.ourExpertiseCollection?.items?.length === 2
                          ? "500px"
                          : "auto"
                      }
                      sx={{
                        display: "flex",
                 
               
                      }}
                    >
                      <div>
                        {data &&
                          data.ourExpertiseCollection.items
                            .filter((item) => item != null)
                            .map((obj, index) => (
                              <>
                                <Box
                                  height={
                                    data?.ourExpertiseCollection?.items?.length ===
                                    1
                                      ? "100%"
                                      : data?.ourExpertiseCollection?.items?.length === 2
                                      ? "50%"
                                      : "auto"
                                  }
                                  borderBottom={
                                    index ===
                                    data?.ourExpertiseCollection?.items.length - 1
                                      ? "none"
                                      : "1px solid white"
                                  }
                                  display="flex"
                                  bgcolor={COLOR_MAP[index % COLOR_MAP.length]}
                                >
                                  <div
                                    onClick={() => {
                                      handleChange(0, index);
                                    }}
                                    className={`${styles.List} ${styles.tabTitle}`}
                                  >
                                    {obj.title}
                                  </div>

                                  <Tabs
                                    orientation="vertical"
                                    aria-label="Vertical tabs example"
                                    sx={{
                                      height: "100%",
                                      width: "100%",
                                      minWidth: "361px",
                                    }}
                                  >
                                    {data?.ourExpertiseCollection?.items[
                                      index
                                    ]?.categoryItemCollection?.items
                                      .filter((item) => item != null)
                                      .map((element: any, nestindex) => (
                                        <Box
                                          bgcolor={
                                            BG_COLOR_MAP[
                                              index % BG_COLOR_MAP.length
                                            ]
                                          }
                                          borderBottom={
                                            nestindex ===
                                            data?.ourExpertiseCollection?.items[
                                              index
                                            ]?.categoryItemCollection.items
                                              .length -
                                              1
                                              ? "none"
                                              : "1px solid white"
                                          }
                                          width="100%"
                                        >
                                          <Tab
                                            onClick={() => {
                                              handleChange(nestindex, index);
                                            }}
                                            onMouseOver={() => {
                                              handleChange(nestindex, index);
                                            }}
                                            onMouseEnter={() =>
                                              setHovered(true)
                                            } // Mouse over, stop the interval
                                            onMouseLeave={() =>
                                              setHovered(false)
                                            } // Mouse leaves, start the interval
                                            label={
                                              <span className={styles.arrowdiv}>
                                                {nestindex === value &&
                                                index === valueMain ? (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginX: "5px",
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="white"
                                                      className="bi bi-caret-right-fill"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginX: "5px",
                                                    }}
                                                  >
                                                    {" "}
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="transparent"
                                                      className="bi bi-caret-right-fill"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                  </Box>
                                                )}
                                                {element?.title}
                                              </span>
                                            }
                                            className={`${styles.item}  ${
                                              styles[`item-${nestindex}`]
                                            } ${
                                              nestindex === value &&
                                              index === valueMain
                                                ? styles.active
                                                : ""
                                            }`}
                                            {...a11yProps(index)}
                                          />
                                        </Box>
                                      ))}
                                  </Tabs>
                                </Box>
                              </>
                            ))}
                      </div>

                      {data.ourExpertiseCollection.items[
                        valueMain
                      ].categoryItemCollection.items
                        ?.filter((item) => item != null)
                        .map((element: any, index) => (
                          <Box
                            className={styles.heightliitems}
                            display="flex"
                            justifyContent="center"
                            alignSelf="center"
                            alignItems="center"
                            sx={{
                              backgroundColor: "#fff",
                              width: value === index ? "100%" : "0%",
                            }}
                          >
                            <TabPanel
                              value={value}
                              valueMain={valueMain}
                              index={index}
                            >
                              <Box color="#F1705A">{element?.title}</Box>
                              <ul className={styles.ulCSS}>
                                {element?.points?.map((index: any) => (
                                  <li>{index}</li>
                                ))}
                              </ul>
                            </TabPanel>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </SectionContainer>
              )}
            </Container>
          </Box>
        )}
    </>
  );
}
