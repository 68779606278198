import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import googleCloud from './path-to-your-image.png'; // Update with the correct image path
import CustomBanner from "@/components/custom-banner";
import { GetBannerDataFragmentQuery, GetBannerDataFragmentResult } from '@/model/GetBannerDataFragment';
import { useQuery } from '@apollo/client';
import noImage from "@/assets/images/noImage.png"

const BannerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100%; // Set the height as a CSS property
`;

const BannerContent = styled(Box)`
  height: 100%; // Set the height as a CSS property
  display: flex;
  flex-direction: column;
  align-items: center; // Fixed typo: align-items
  justify-content: center;
`;

const BannerImage = styled('img')`
  height: 100%; // Set the height as a CSS property
  max-width: 55%;
  object-fit: contain;
`;

const BannerTitle = styled(Typography)`
  color: #ffffff;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 0.35rem;
  letter-spacing: 3px;
`;

const BannerDescription = styled(Typography)`
  color: #ffffff;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
`;

function BannerData({ HeadingId,
}:{  HeadingId: string;}) {
  const getBannerDataByIdResult = useQuery(GetBannerDataFragmentQuery, {
    variables: { entryId: HeadingId },
  });
  const data: GetBannerDataFragmentResult = getBannerDataByIdResult.data;
  const projectLoading: boolean = getBannerDataByIdResult.loading;
  return (
    <>
    {!projectLoading && data && data.bannerData   && (    <CustomBanner>
      <BannerContainer
        height="100%"
        sx={{ marginTop:{md:"-2.5rem"}, justifyContent: { xs: "center", md: "space-between" }  }}
      >
        <BannerContent sx={{alignItems:{xs:"center",md:"flex-start"}}}>
          <BannerTitle
            sx={{
              textAlign: { xs: "center", md: "left" },
              fontSize: { sm: "50px", xs: "35px" },
              color: "#fff",
            }}
          >
           {data?.bannerData?.title}
          </BannerTitle>
          <BannerDescription
            sx={{
              textAlign: { xs: "center", md: "left" },
              color: "#fff",
            }}
          >
                  {data?.bannerData?.descriptions}
          </BannerDescription>
        </BannerContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <BannerImage
            height="320px"
            width="320px"
            src={data?.bannerData?.icon?.url}
            sx={{ display: { md: "block", xs: "none" } }}
            alt="Google Cloud Platform"
          />
        </Box>
      </BannerContainer>
    </CustomBanner>)}
    </>
  );
}

export default BannerData;
