import { Box, Container, Link, styled, Typography } from "@mui/material";
import JobSearch from "./job-search";
import SectionHeading from "@/components/section-heading";
import BannerData from "@/components/banner-data/bannerData";

const ResumeLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  border-radius: 10rem;
  padding: 0.8rem 2rem;
  font-weight: 600;
  background-image: linear-gradient(to right, #F1705A 0%, #F0AD56 100%);
  transition: opacity 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  border: none;
  width: 65%;
  display: flex;
  justify-content: center;
`;

export default function Career() {
  return (
    <Box>
      <BannerData HeadingId="1hvXsBe6SDByXBbylcGl3i"/>
      <JobSearch />
      <Box
        sx={{
          backgroundImage: "linear-gradient(to bottom, #edf4fb, #fff)",
          padding: "5rem 0",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <SectionHeading marginTop="0px" color="#3C4858" HeadingId="BmUEVZc0371dtV6puAxOZ"></SectionHeading>
          <ResumeLink href="mailto:recruting@cloudup.com">
            Send Resume Here
          </ResumeLink>
        </Container>
      </Box>
    </Box>
  );
}
