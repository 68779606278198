import { MediaItem } from "@/model/GetMediaItemFragement";
import { Box, Link, Typography } from "@mui/material";
import pdfIcon from "@/assets/icons/pdf.svg";
import noImage from "@/assets/images/noImage.png"
import { pdfLink } from "@/model/GetDatasheetFragement";


export default function SheetView({
  title = '',
  description = "",
  sheet,
  index,
  pdf,
}: {
  title?: string;
  description?: string;
  sheet?: MediaItem;
  index?: number;
  pdf?:pdfLink;
}) {
  return (
    <Box
      sx={{
        p: { sm: "1rem", xs: "5px" },
        background: "#fff5e4",
        marginY: "1.5rem",
        marginX: { xs: "auto" },
      }}
      boxShadow="2px 2px 10px #00000029"
      flexBasis="45%"
      marginX={2}
      display="flex"
      flexDirection="column"
    >
      <Typography color="#3C4858" fontSize="20px" fontWeight="bold" mb={1}>
        {title}
      </Typography>
      <Typography color="#484848" fontSize="16px">
        {description?description:""}
      </Typography>
      <Box
        component="img"
        src={sheet?.url ? sheet?.url : noImage}
        alt={title}
        m="auto"
      />
      {pdf?.url &&     <Link href={pdf?.url} download target="_blank" underline="none">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography
            color="#858585"
            fontSize="11px"
            mr={1}
            sx={{ "&:hover": { color: "#27283C" } }}
          >
            Download PDF
          </Typography>
          <Box
            component="img"
            src={pdfIcon}
            alt="PDF Icon"
            width={16}
            height={16}
          />
        </Box>
      </Link>}
  
    </Box>
  );
}
