import CustomInput from "../custom-input";
import CustomSelect from "../custom-select";
import CustomButton from "../custom-button";
import Box from "@mui/material/Box";
import styles from "./index.module.css";
import contactUsbg from "../../assets/images/contact-us-bg.svg";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Container } from "@mui/material";
import HorizontalRuler from "../horizontal-ruler-heading";

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    // console.log("data", data);
  };

  return (
    <>
      <Box overflow="hidden" paddingY="100px" className={styles.contactUsContainer}>
        <Container maxWidth="xl" >
        <Typography
                      color="#3C4858"
                      fontSize={30}
                      fontWeight={700}
                      textAlign="center"
                    >
                      GET FREE SUPPORT
                    </Typography>
                    <HorizontalRuler/>
          <Box
            sx={{justifyContent:{md:"space-between",xs:"center"},
              paddingX: {
                xl: "6rem",
                lg: "1rem",
                md: "1rem",
                sm: "1rem",
                xs: "1rem",
              },
            }}
            display="flex"
          >

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: { lg: "30%" } }}
            >
              <Box
                sx={{ display: { md: "block", xs: "none" } }}
                padding="0.5rem"
                component="img"
                height="70%"
                src={contactUsbg}
              />
            </Box>
            <Box
              sx={{ width: { lg: "60%",xs:"100%" } }}
              position="relative"
              pt={4}
              pb={4}
            >
              <form
                className={styles.formStyle}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Box
                  position="relative"
                  display="flex"
                  sx={{
                    width: { md: "80%", xs: "100%" },
                  }}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                  >
   
                    <Box width="100%">
                      <Box display="flex" flexDirection="column" width="100%">
                        <Box width="100%" m={1} my={2}>
                          <CustomSelect
                            label="Services"
                            options={[
                              "Devops",
                              "Infrastructure Automation",
                              "Data Migration",
                              "Data Visualization",
                              "Data Integration",
                              "Cloud Migration",
                            ]}
                            value={""}
                            onChange={(value: string) => {}}
                          />
                        </Box>
                        <Box width="100%" m={1} my={2}>
                          <CustomSelect
                            label="Category"
                            options={[
                              "Troubleshoot",
                              "Review Architecture",
                              "Recommend tools and technology",
                            ]}
                            value={""}
                            onChange={(value: string) => {}}
                          />
                        </Box>
                        <Box width="100%" m={1} my={2}>
                          <CustomInput label="Email" />
                        </Box>
                        <Box
                          width="100%"
                          display="flex"
                          sx={{ justifyContent: { md: "left", xs: "center" } }}
                          mt={3}
                          marginLeft={1}
                        >
                          <CustomButton title="submit" onClick={() => {}} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
