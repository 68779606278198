import { Box, Typography, styled } from "@mui/material";
import BannerTitle from "@/components/custom-banner/banner-title";
import BannerDescription from "@/components/custom-banner/banner-description";
import { BannerImage } from "@/model/GetBannerFragment";
import styles from "./what.module.css";
import noImage from "@/assets/images/noImage.png"

const BannerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-bottom: 6rem;
  @media (max-width: 899px) {
    padding-bottom: 0;
    align-items: center;
    text-align: center;
  }
`;

const BannerImages = styled(Box)``;

const BannerImageWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  @media (max-width: 350px) {
    flex-direction: column;
  }
`;

const BannerImageItem = styled(Box)`
  flex-basis: calc(33.33% - 20px);
  text-align: center;
`;

export default function WhatWeDo({
  title="",
  description="",
  images,
  length,
  name="",
}: {
  title: string;
  description: string;
  images: BannerImage[];
  length:any;
  name:string
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.Direction}
    >
      <BannerWrapper className={styles.BannerTextWrapper}>
        <BannerTitle title={title} />
        <h1 style={{ fontWeight: "900", color: "#fff" }}>{name}</h1>
        <BannerDescription description={description} />
      </BannerWrapper>
      <BannerImages
        className={styles.BannerImageWrapper}
        sx={{ paddingRight: { md: "1rem", xs: "0rem" } }}
      >
        <BannerImageWrapper height="100%">
          {images.map((item, index) => (
           <BannerImageItem
           className={styles.BannerCards}
           key={index}
           textAlign="center"
           flexDirection="column"
           display="flex"
           justifyContent="center"
           alignItems="center"
           sx={{
             height: length<=3 ?{
               sm: "100%",
               xs: "100%",
             }:{},
             padding: { sm: "1rem", xs: "0.5rem" },
             borderRadius: "20px",
             border:'1px solid #fff',
             backgroundColor: "transparent",
             "&:hover": {
               backgroundColor: "transparent",
             },
           }}
         >
              <Box     width={58}
                height={72}><Box
                component="img"
                src={item?.url  }
                alt={item?.title}
                width={58}
              /></Box>
              <Typography
                fontWeight="600"
                color="white"
                mb={0}
                sx={{ fontSize: "0.8rem",paddingTop:"5px" }}
              >
                {item?.title}
              </Typography>
            </BannerImageItem>
          ))}
        </BannerImageWrapper>
      </BannerImages>
    </Box>
  );
}
