import { gql } from "@apollo/client";
import { MediaItem } from "./GetMediaItemFragement";

export const GetProjectFragementQuery = gql`
  query projectCollectionQuery {
    projectCollection {
      items {
        sys {
          id
        }
        title
        description
        logo {
          url
          title
        }
      }
    }
  }
`;

export const GetProjectByIdQuery = gql`
  query projectEntryQuery($entryId: String!) {
    project(id: $entryId) {
      sys {
        id
      }
      title
      description
      industry
      service
      logo{
        url
        title
      }
    }
  }
`;

export interface GetProjectByIdResult {
  project: ProjectDetail
}

export interface ProjectSysId {
  id: string
}

export interface ProjectDetail {
  sys: ProjectSysId;
  title: string;
  description: string;
  industry: string;
  service: string;
  logo: MediaItem;
}

export interface GetProjectFragementQueryResult {
  projectCollection: {
    items: ProjectDetail[];
  };
}
