import { gql } from "@apollo/client";

export const GetWhyCloudUpQuery = gql`
  query whyCloudUpCollectionQuery {
    whyCloudUpCollection(order: order_ASC){
      items {
        title
        order
        descriptionwhycloudup
        categoryItemsCollection(limit:10) {
          items {
            title
            description
            icon {
              url         
               }
           
          }
        }
      }
    }
  }
`;

export interface CategoryCollection {
  title: string;
  description: string;
  icon:ServiceImage;
}
export interface ServiceImage {
  url: string;
}
export interface WhyCloudUp {
  title: string;
  order: number;
  descriptionwhycloudup:string;
  categoryItemsCollection: { items: CategoryCollection[] };
}
export interface GetWhyCloudUpQueryFragmentResult {
  whyCloudUpCollection: {
    items: WhyCloudUp[];
  };
}
