import { gql } from '@apollo/client'

export const GetBannerFragmentQuery = gql`
  query bannerCollectionQuery($page: String!) {
    bannerCollection(where: { page: $page }, order: order_ASC) {
      items {
        sys {
          id
        }
        title
        name
        description
        imagesCollection {
          items {
            url
            title
            description
            fileName
          }
        }
      }
    }
  }
`;

export interface BannerImage {
  title: string;
  url: string;
  description?: string;
  fileName: string;
}

export interface BannerItem {
  title: string;
  description: string;
  name:string;
  imagesCollection: {
    items: BannerImage[];
  }
}

export interface GetBannerFragmentResult {
  bannerCollection: {
    items: BannerItem[]
  }
}