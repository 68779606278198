import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { GetSectionHeadingById, SectionHeadingByIdResult, SectionHeadingIDItem} from "@/model/GetSectionHeadingByIdFragment"
import { useQuery } from "@apollo/client";
import loadingGIF from "../../assets/images/LoadingGif.gif"

export default function SectionHeading({
  HeadingId,
  color = "#888888",
  descriptionColor="#888888",
  marginTop="100px",
 
}: {
  HeadingId: string;
  color?: "white" | "black" | "blue" | "dark blue"|"#3C4858"|"#2878F7"|"#888888";
  descriptionColor?: "white" | "black" | "blue" | "dark blue"|"#3C4858"|"#2878F7"|"#888888";
  marginTop?: string;
}) {
  const getSectionHeadingByIdResult = useQuery(GetSectionHeadingById, {
    variables: { entryId: HeadingId },
  });
  //for all
  const projectData: SectionHeadingByIdResult = getSectionHeadingByIdResult.data;
  const projectLoading: boolean = getSectionHeadingByIdResult.loading;
  //
  const titleColor = useMemo(() => {
    if (color === "white") return "#FFF";
    if (color === "black") return "#333";
    if (color === "blue") return "#03a9f4";
    return "#0474e5";
  }, [color]);

  return (
    projectLoading ? (
      <Box display="flex" paddingY="1rem" justifyContent="center"><Box height="60px" width="60px" component="img" src={loadingGIF}></Box></Box>
    ):
    <Box  marginTop= {marginTop}
    marginBottom ='15px'>
    <Typography
      color={color}
      textAlign="center"
      fontWeight="bold"
      sx={{fontSize:{sm:"37px",xs:"30px"}}}
    >
      {projectData?.pageTitles?.name}
    </Typography>
    {projectData.pageTitles.page && (
          <Typography
          color={descriptionColor}
          marginTop='1rem'
          textAlign="center"
          sx={{fontSize:{sm:"15px",xs:"15px"}}}
        >
          {projectData?.pageTitles?.page}
        </Typography>
    )}
    </Box>
  );
}
