import { useQuery } from "@apollo/client";
import { Box, Container, Typography } from "@mui/material";
import styles from "./accerlerator-library.module.css";
import Graphic from "../../../../assets/images/GRAPHICS.png";
import SectionHeading from "@/components/section-heading";
import accerlaertorLibrary from "../../../../assets/images/AccerleratorLibrary.png"
import {
  GetTechnologiesQuery,
  GetTechnologiesResult,
} from "@/model/GetTechnologyFragement";
import HorizontalRuler from "@/components/horizontal-ruler-heading";

const AcceleratorLibrarySection = () => {
  const LibraryResult = useQuery(GetTechnologiesQuery);
  const Data: GetTechnologiesResult = LibraryResult.data;
  const LibraryLoading = LibraryResult.loading;

  return (
    <Box className={styles.ourDiffHeaderWrapper}>
      {!LibraryLoading && Data && Data.technologyCollection && Data.technologyCollection.items.length>0 && (
        <Container maxWidth="xl">
          <Box
            className={styles.mainContainerBox}
            sx={{
              marginBottom:"100px",
              paddingX: { xl: "6rem", md: "1rem", sm: "1rem", xs: "1rem" },
            }}
          >
            <SectionHeading
              color="#3C4858"
              HeadingId="3BHCC2UxGdSjEB7W1hwMSD"
            ></SectionHeading>
             <HorizontalRuler/>
            <Box
          component="img"
          width="100%"
          height="100%"
          src={accerlaertorLibrary}
        ></Box>
            {/* <Box
              textAlign="center"
              fontSize="calc(1 * 1vw)"
              display="flex"
            >
              <Box
                display="flex"
                width="10%"
                justifyContent="end"
                alignItems="center"
              >
                {" "}
                <Box
                  borderRadius="1.5rem"
                  bgcolor="#0087c6"
                  padding="10%"
                  color="#fff"
                  fontWeight="bold"
                >
                  {Data.technologyCollection.items[12].title}
                </Box>
              </Box>
              <Box
                width="80%"
                display="inline-flex"
                position="relative"
              >
                <Box
                  component="img"
                  width="100%"
                  height="100%"
                  src={Graphic}
                ></Box>
                <Box
                  borderRadius="10%"
                  sx={{ transform: "rotate(-45deg)" }}
                  position="absolute"
                  left="9%"
                  top="28%"
                >
                  <Box>{Data.technologyCollection.items[6].title}</Box>
                </Box>{" "}
                <Box borderRadius="10%" position="absolute" top="4%" left="23%">
                  <Box>{Data.technologyCollection.items[5].title}</Box>
                </Box>{" "}
                <Box borderRadius="10%" position="absolute" top="4%" left="41%">
                  <Box>{Data.technologyCollection.items[4].title}</Box>
                </Box>
                <Box
                  borderRadius="10%"
                  sx={{ transform: "rotate(45deg)" }}
                  position="absolute"
                  left="8%"
                  bottom="25%"
                >
                  <Box>{Data.technologyCollection.items[3].title}</Box>
                </Box>{" "}
                <Box
                  borderRadius="10%"
                  position="absolute"
                  bottom="3%"
                  left="23%"
                >
                  <Box>{Data.technologyCollection.items[2].title}</Box>
                </Box>{" "}
                <Box
                  borderRadius="10%"
                  position="absolute"
                  bottom="3%"
                  left="41%"
                >
                  <Box>{Data.technologyCollection.items[1].title}</Box>
                </Box>
                <Box
                  borderRadius="10%"
                  position="absolute"
                  bottom="3%"
                  left="77%"
                >
                  <Box>{Data.technologyCollection.items[0].title}</Box>
                </Box>
                <Box
                width="21%"
                  borderRadius="10%"
                  position="absolute"
                  left="28%"
                  top="19%"
                >
                  <Box sx={{borderTopRightRadius:"0.7rem",borderTopLeftRadius:"0.7rem",}} padding="5%" color="#fff" bgcolor="#0072bf">
                    {Data.technologyCollection.items[11].title}
                  </Box>
                  <Box sx={{borderBottomRightRadius:"0.7rem",borderBottomLeftRadius:"0.7rem",}} padding="5%" bgcolor="#d9ecf6">
                    {Data.technologyCollection.items[11].description}
                  </Box>
                </Box>
                <Box
                   width="25%"
                  borderRadius="10%"
                  position="absolute"
                  left="50%"
                  top="19%"
                >
                  <Box sx={{borderTopRightRadius:"0.7rem",borderTopLeftRadius:"0.7rem",}} padding="5%" color="#fff" bgcolor="#0072bf">
                    {Data.technologyCollection.items[10].title}{" "}
                  </Box>
                  <Box sx={{borderBottomRightRadius:"0.7rem",borderBottomLeftRadius:"0.7rem",}} padding="5%" bgcolor="#d9ecf6">
                    {Data.technologyCollection.items[10].description}
                  </Box>
                </Box>
                <Box
                   width="21%"
                  borderRadius="10%"
                  position="absolute"
                  left="50%"
                  bottom="19%"
                >
                  <Box padding="5%" sx={{borderTopRightRadius:"0.7rem",borderTopLeftRadius:"0.7rem",}} color="#fff" bgcolor="#00b5f1">
                  {Data.technologyCollection.items[9].title}                  </Box>
                  <Box sx={{borderBottomRightRadius:"0.7rem",borderBottomLeftRadius:"0.7rem",}} padding="5%" bgcolor="#d9ecf6">
                  {Data.technologyCollection.items[9].description}
                  </Box>
                </Box>
                <Box  position="absolute" left="60%">
                  <Box padding="10%" paddingX="15%" borderRadius="5rem" color="#fff" bgcolor="#003d5e">
                  {Data.technologyCollection.items[8].title}
                  </Box>
                </Box>
              </Box>
              <Box
                width="10%"
                display="flex"
                justifyContent="start"
                alignItems="end"
              >
                {" "}
                <Box
                  padding="5%"
                  color="#fff"
                  borderRadius="10%"
                  bgcolor="#88ecfc"
                  marginLeft="1rem"
                >
                  {Data.technologyCollection.items[7].title}
                </Box>
              </Box>
            </Box> */}
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default AcceleratorLibrarySection;
