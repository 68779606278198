import HorizontalRuler from "@/components/horizontal-ruler-heading";
import SectionHeading from "@/components/section-heading";
import SectionTitle from "@/components/section-title";
import noImage from "@/assets/images/noImage.png"

import {
  GCPSolutionByIdQuery,
  GCPSolutionByIdResult,
  GCPSolutionContainerQuery,
  GCPSolutionContainerResult,
} from "@/model/GetGoogleCloudFragement";
import { useQuery } from "@apollo/client";
import { Box, Container, Skeleton, Typography } from "@mui/material";

const COLOR_MAP = ["#005BB7", "#509500", "#D37E00",];
const COLOR_MAP_DESCRIPTION = ["#daeafb", "#e9f2eb", "#faede4"];
const BG_COLOR_MAP = ["#fff", "#F6F7FD"];

export default function Solutions() {
  const solutionContianerResult = useQuery(GCPSolutionContainerQuery);
  const data: GCPSolutionContainerResult =
    solutionContianerResult.data;
  const loading: boolean = solutionContianerResult.loading;
  return (
    <>    {!loading &&
      data &&
      data.gcpSolutionContainerCollection &&
      data.gcpSolutionContainerCollection.items.length > 0 && (
    <Box component="section" display="flex" flexDirection="column">
      <SectionHeading
        color="#3C4858"
        HeadingId="62iSk7sQUwPnBgmRo8blv7"
      ></SectionHeading>
      <HorizontalRuler />

      {loading && (
        <Skeleton variant="rectangular" width="100%" height="150px" />
      )}
  
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {data?.gcpSolutionContainerCollection?.items?.filter(item => item != null).map((item, index) => (
              <Box
                width="100%"
                paddingY="5rem"
                paddingTop={index == 0 ? "1rem" : "5rem"}
                sx={{
                  backgroundColor: BG_COLOR_MAP[index % BG_COLOR_MAP.length],
                }}
                key={`container-${index}`}
              >
                <Container maxWidth="xl">
                  <Box
                    display="flex"
                    justifyContent="center"
                    marginBottom={3}
                    sx={{
                      flexDirection: { md: "row", xs: "column" },
                      paddingX: {
                        xl: "6rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Box
                      flexBasis="20%"
                      position="relative"
                      sx={{ display: { md: "flex", xs: "none" } }}
                      justifyContent="center"
                      alignItems="flex-start"
                      mb={3}
                      marginRight="2rem"
                    >
                      <Box
                        maxHeight="110px"
                        maxWidth="110px"
                        component="img"
                        src={item?.icon?.url ? item?.icon?.url:noImage}
                        alt={item?.icon?.title}
                      />
                    </Box>

                    <Box alignItems="flex-start" flexBasis="50%">
                      <Typography
                        fontWeight={"bold"}
                        lineHeight="1.47"
                        color="#3C4858"
                        mb={2.5}
                        sx={{
                          textAlign: { md: "left", xs: "center" },
                          fontSize: { sm: "30px", xs: "25px" },
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: { md: "left", xs: "center" },
                        }}
                        fontSize="18px"
                        lineHeight={1.5}
                        color="
                      #3C4858"
                        mb={0}
                      >
                        {item?.description}
                      </Typography>
                    </Box>
                  </Box>
                  {item?.solutionsCollection?.items?.length > 0 && (
                    <Box
                      display="flex"
                      sx={{
                        flexDirection: { md: "row", xs: "column" },
                        paddingX: {
                          xl: "6rem",
                          md: "1rem",
                          sm: "1rem",
                          xs: "1rem",
                        },
                      }}
                      justifyContent="space-between"
                      mb={2}
                    >
                      {item?.solutionsCollection?.items?.filter(item => item != null).map(
                        (solution, sIndex) => (
                          <SolutionCard
                            key={sIndex}
                            id={solution?.sys?.id}
                            index={sIndex}
                          />
                        )
                      )}
                    </Box>
                  )}
                </Container>
              </Box>
            ))}
          </Box>
    
    </Box>
        )}
    </>
  );
}

export function SolutionCard({ id, index }: { id: string; index: number }) {
  const solutionByIdResult = useQuery(GCPSolutionByIdQuery, {
    variables: { entryId: id },
  });
  const data: GCPSolutionByIdResult = solutionByIdResult.data;
  const loading: boolean = solutionByIdResult.loading;
  return loading ? (
    <Skeleton variant="rectangular" width="100%" height="50px" />
  ) : !loading && data.gcpSolutions ? (
    <Box
      flexBasis={"27%"}
      marginX="0.5rem"
      sx={{ marginY: { md: "0rem", xs: "1.2rem" },backgroundColor:COLOR_MAP[index % COLOR_MAP.length] }}
      borderRadius="0.5rem"
      boxShadow="1px 3px 70px 0 rgba(2, 34, 131, 0.25)"
      overflow="hidden"
    >
      <Typography
        fontSize="18px"
        fontWeight="bold"
        color="#fff"
        paddingX="1rem"
        lineHeight={1}
        my={3}
      >
        {data?.gcpSolutions?.title}
      </Typography>
      <Typography
        bgcolor={COLOR_MAP_DESCRIPTION[index % COLOR_MAP_DESCRIPTION.length]}
        height="100%"
        fontSize="16px"
        color="
        #3C4858"
        padding="1rem"
        lineHeight={1.31}
        mb={0}
      >
        {data?.gcpSolutions?.description}
      </Typography>
    </Box>
  ) : null;
}
