import { gql } from '@apollo/client'

export const GetBannerDataFragmentQuery = gql`
query bannerDataEntryQuery ($entryId: String!){
  bannerData(id: $entryId) {
    sys {
      id
    }
    title
    descriptions
    icon{
      url
    }
    # add the fields you want to query
  }
}`;

  export interface BannerImage {
    url: string;
  }
  export interface GetBannerDataFragmentResult {
    bannerData: {
      title: string;
      sys: {
          id: string;
        };
      descriptions: string;
      icon:BannerImage
    }
  }