import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./pages/about";
import PageLayout from "./pages/layout";
import GoogleCloudPlatform from "./pages/google-cloud-platform";
import Career from "./pages/career";
import DataSheet from "./pages/datasheet";
import JobDetail from "./pages/career/job-detail";
import { analytics, app, isSupported, logPageEvent } from './utils/firebase';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<PageLayout />}>
      <Route index element={<AboutPage />} />
      <Route index  path="/about" element={<AboutPage />} />
          <Route
            path="/professional-services"
          />
          <Route
            path="/data-analytics-services"
          />
          <Route
            path="/google-cloud-platform"
            element={<GoogleCloudPlatform />}
          />
          <Route path="/datasheet" element={<DataSheet />} />
          <Route path="/career" element={<Career />} />
        </Route>
        <Route path="/job-openings/:jobTitle" element={<JobDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
