import { Typography } from "@mui/material";
import { useMemo } from "react";

export default function SectionTitle({
  title,
  color = "#3C4858",
}: {
  title: string;
  color?: "white" | "black" | "blue" | "dark blue"|"#3C4858"|"#2878F7";
}) {
  const titleColor = useMemo(() => {
    if (color === "white") return "#FFF";
    if (color === "black") return "#333";
    if (color === "blue") return "#03a9f4";
    return "#0474e5";
  }, [color]);

  return (
    <Typography
      color={color}
      marginTop= '100px'
      marginBottom ='15px'
      textAlign="center"
      fontWeight="bold"
      sx={{fontSize:{sm:"37px",xs:"30px"}}}
    >
      {title}
    </Typography>
  );
}
