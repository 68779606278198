import { Box } from "@mui/material";
import styles from "./index.module.css";

export default function HorizontalRuler() {
  return (
    <Box paddingBottom="2rem">
      <hr className={styles.rulerBlack} />
      <hr className={styles.rulerOrange} />
    </Box>
  );
}
