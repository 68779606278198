import { gql } from "@apollo/client";

export const GetLatestProjectQuery = gql`
query latestProjectsCollectionQuery {
  latestProjectsCollection(order: order_ASC) {
    items {
      sys {
        id
      }
      order
       icon {
        title
        fileName
        url
      }

      title
      description
      link
    }
  }
}
`;
export interface LatestProjectImage {
  title: string;
  fileName: string;
  url: string;
}
export interface LatestProject {
  title: string;
  description: string;
  link?: string;
  icon: LatestProjectImage;

}

export interface GetLatestProjectResult {
  latestProjectsCollection: {
    items: LatestProject[]
  }
}