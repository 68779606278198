import { useQuery } from "@apollo/client";
import { Box, Container, Typography } from "@mui/material";
import styles from "./company-information.module.css";
import SectionHeading from "@/components/section-heading";
import {
  CompanyWorkCompanyStaticsEntryQuery,
  CompanyWorkIndustriesWeWorkEntryQuery,
  GetCompanyInformationFragmentResult,
  GetCompanyInformationFragmentResultbyID,
  dataAnalyticsOfferingsEntryQuery,
} from "@/model/GetCompanyInformationFragment";
import SectionTitle from "@/components/section-title";
import HorizontalRuler from "@/components/horizontal-ruler-heading";
import Counter from "@/components/counter/Counter";
import noImage from "@/assets/images/noImage.png"

const CompanyInformation = () => {
  //for all
  const ComapanyInformationResult = useQuery(dataAnalyticsOfferingsEntryQuery);
  const data: GetCompanyInformationFragmentResult =
    ComapanyInformationResult.data
  const loading: boolean = ComapanyInformationResult.loading;
  //for all
  const ComapanyWeWorkResult = useQuery(CompanyWorkCompanyStaticsEntryQuery);
  const WeWorkdata: GetCompanyInformationFragmentResultbyID =
  ComapanyWeWorkResult.data
  const WeWorkloading: boolean = ComapanyWeWorkResult.loading;
  //for all
  const ComapanyIndustriesResult = useQuery(CompanyWorkIndustriesWeWorkEntryQuery);
  const Industriesdata: GetCompanyInformationFragmentResultbyID =
  ComapanyIndustriesResult.data;
  const Industriesloading: boolean = ComapanyIndustriesResult.loading;
  return (
    <Box>
      {!loading &&
        data &&
        data.dataAnalyticsOfferingsCollection &&
        data.dataAnalyticsOfferingsCollection.items.length > 0 && (
          <>
            {!WeWorkloading &&
        WeWorkdata &&
        WeWorkdata.dataAnalyticsOfferings && (
            <Box>
              <Container maxWidth="xl">
                <Box
                  sx={{
                    paddingX: {
                      xl: "6rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                    paddingTop: "1rem",
                    paddingBottom: { xs: "100px" },
                  }}
                >
                  <Box>
                    <SectionTitle
                      color="#3C4858"
                      title={
                        WeWorkdata?.dataAnalyticsOfferings?.title
                      }
                    ></SectionTitle>
                    <HorizontalRuler />
                    <Box
                      display="flex"
                      sx={{
                        alignItems:"center",flexWrap:{md:"nowrap",xs:"wrap"},
                      flexDirection: { md: "row", sm: "row",xs: "row" },
                      justifyContent: { md: "space-between", xs: "center" },
                    }}
                    >
                      {WeWorkdata?.dataAnalyticsOfferings?.categoryItemsCollection?.items?.filter(item => item != null).map(
                        (item, index) => (
                          <Box
                            height="100%"
                             sx={{width:{md:`${100/(WeWorkdata?.dataAnalyticsOfferings?.categoryItemsCollection?.items?.length)}%` , sm :"50%",xs:"50%"}}}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            padding="1rem"
                          >
                            {" "}
                            <Box
                            display="flex"
                              textAlign="center"
                              fontSize="45px"
                              color="#F1705A"
                              marginTop="20px"
                              fontWeight="bolder"
                            >
                              {" "}
                              <Counter MaxNumber={item?.description}/>+
                            </Box>
                            <Box
                            minHeight="42px"
                              textAlign="center"
                              fontSize="17px"
                              color="#3C4858"
                              marginTop="20px"
                              fontWeight="899"
                            >
                              {" "}
                              {item?.title}
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>  )
}
{!Industriesloading &&
        Industriesdata &&
        Industriesdata.dataAnalyticsOfferings &&(
            <Box
              bgcolor="
#F6F7FD"
            >
              <Container maxWidth="xl">
                <Box
                  sx={{
                    paddingX: {
                      xl: "6rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    },
                    paddingTop: "1rem",
                    paddingBottom: { xs: "100px" },
                  }}
                >
                  <Box>
                    <SectionTitle
                      color="#3C4858"
                      title={
                        Industriesdata?.dataAnalyticsOfferings?.title
                      }
                    ></SectionTitle>
                    <HorizontalRuler />
                    <Box
                      display="flex"
                  
                      alignItems="center"
                      sx={{flexWrap:{md:"nowrap",xs:"wrap"},
                        flexDirection: { md: "row", sm: "row",xs: "row" },
                        justifyContent: { md: "space-between", xs: "center" },
                      }}
                    >
                      {Industriesdata?.dataAnalyticsOfferings?.categoryItemsCollection?.items?.filter(item => item != null).map(
                        (item, index) => (
                          <Box
                            padding="1rem"
                            height="100%"
                            
                            sx={{width:{md:`${100/(Industriesdata?.dataAnalyticsOfferings?.categoryItemsCollection?.items?.length)}%` , sm :"50%",xs:"50%"}}}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            {" "}
                            <Box
                              borderRadius="22%"
                              padding="0.5rem"
                              component="img"
                              src={item?.icon?.url ? item?.icon?.url:noImage}
                              alt={item?.title}
                             height="80px"
                              width="80px"
                             
                            />
                            <Box
                              textAlign="center"
                              fontSize="17px" 

                              color="#3C4858"
                              marginTop="20px"
                              fontWeight="899"
                              minHeight="42px"
                            >
                              {" "}
                              {item?.title}
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>)}
          </>
        )}
    </Box>
  );
};

export default CompanyInformation;
